import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css'



const Account = (props) => {

    const [getAccount, setAccount] = useState({})
    const [getPopup, setPopup] = useState(false)
    const cookie = new Cookies()

    const get_account = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_account`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (dataset.status === true) {
                setAccount(dataset.data.data)
            }

        } catch (error) {
            console.log(error)
        }
    }


    const change_password = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/change_password`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    password: document.getElementById("password").value
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        props.check_cookie()
        get_account()
    }, [""])



    const PopupAcq = () => {
        if (getPopup) {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setPopup(false) }}>
                    </div>
                    <div className="childPopup">
                        <div>
                            <label>New Password:</label>
                        </div>
                        <div>
                            <input id="password"></input>
                        </div>
                        <div>
                            <button onClick={(e) => { change_password() }} className='tablebtn'>Change password</button>
                        </div>
                    </div>
                </>
            )
        }
    }


    return (
        <>
            <div className="parentTransaction"  data-aos="fade-left">
                <div className="blockside">
                    <div className="childTransaction">
                        <div className="tabletie">
                            <tr>
                                <th>Name:</th>
                                <td>{getAccount.name}</td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>{getAccount.email}</td>
                            </tr>
                            <tr>
                                <th>Mobile:</th>
                                <td>{getAccount.phone}</td>
                            </tr>
                        </div>
                    </div>
                    <div className="buttonset">
                        <button onClick={(e) => { setPopup(true) }}>Change Password</button>
                    </div>
                    < PopupAcq />
                </div>
            </div>
        </>
    )
}


export default Account