import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import * as XLSX from "xlsx";
import '../css/Common.css';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";


const Bulk = (props) => {
    const [getMerchant, setMerchant] = useState("")
    const [getMerchantList, setMerchantList] = useState([{}])

    const cookie = new Cookies()





    const get_merchant = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const handlecsvRender = async (file) => {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            let arr = []
            for (let i = 1; i < data.length; i++) {
                if (data[i][0] === undefined || data[i][0] === "") {
                    break;
                }
                var temp = {
                    amount: Math.round(data[i][0] * 100),
                    mode: data[i][1],
                    account: data[i][2],
                    ifsc: data[i][3],
                    order_id: data[i][4] || new Date().getTime(),
                    name: data[i][5] || "empty",
                }
                arr.push(temp)
            }
            props.setCsvFile(arr)
            props.setTransaction([])
            // setTransactionlist([])
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);

    }

    // const bulk_upload = async () => {
    //     try {
    //         const resp = await fetch(`${process.env.REACT_APP_URL}/admin/bulk_upload`, {
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 token: cookie.get('user'),
    //                 data: getCsvFile,
    //                 ...getMerchant
    //             }),
    //             headers: {
    //                 'Content-type': 'application/json; charset=UTF-8',
    //             }
    //         })
    //         if (resp.status === 200) {
    //             const dataset = await resp.json()
    //             if (dataset.status === true) {
    //                 setCsvFile([])
    //                 setTransaction(dataset.data.data)
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }









    useEffect(() => {
        // props.check_cookie()
        get_merchant()
    }, [])


    const [isActive, setActive] = useState(true);
    const [defaulttext, setDefaulttext] = useState("View Options")

    function showOptions() {
        let height_transition_div = document.getElementById('height_transition_div');
        height_transition_div.classList.toggle('show_div')
        setActive(!isActive)
        setDefaulttext(!defaulttext)
    }



    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="right_dropdown_icon_container">
                        <button onClick={showOptions}>{defaulttext ? "View Options" : 'Close Options'} {isActive ? <IoIosArrowDropdown className='dropdown_icon' /> : <IoIosArrowDropup className='dropdown_icon' />}</button>
                    </div>
                    <div className='height_transition_div' id='height_transition_div'>
                        <div className="childTransaction">
                            {/* <PopupView /> */}
                            <table>
                                <tr>
                                    <td>
                                        <label>Select File:</label>
                                    </td>
                                    <td>
                                        {
                                            true ? <input type="file" onChange={(e) => { handlecsvRender(e.target.files[0]) }} /> : <input type="file" onChange={(e) => { handlecsvRender(e.target.files[0]) }} disabled />
                                        }
                                    </td>
                                </tr>

                            </table>
                            <table>
                                <tr>
                                    <td>
                                        <label>Merchant:</label>
                                    </td>
                                    <td>
                                        <select name="merchant" onChange={(e) => { setMerchant(e.target.value) }}>
                                            <option>Select Merchant:</option>
                                            {
                                                getMerchantList.map((e, index) => {
                                                    return (
                                                        <option value={e.id}>{e.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="buttonset">
                            {true ? <button onClick={(e) => { props.upload_log(getMerchant) }}>Submit</button> : <button onClick={(e) => { }} disabled>Search Merchant</button>}
                        </div>
                    </div>
                </div>
                <div style={{ "text-align": "center" }} className="percentagebar">
                    <progress id="file" max={props.getMaxvalue} value={props.getCurrentvalue}>hello</progress>
                    <label>{props.getRunning}%</label>
                    {(props.getRunning === "100.00") ? <label>completed</label> : <label></label>}
                </div>
                <div className="tran_table">
                    {/* <div className="rowscount">
                        <select onBlur={(e) => { setRow(e.target.value); }}>
                            <option value={10}>10 Rows</option>
                            <option value={20}>20 Rows</option>
                            <option value={50}>50 Rows</option>
                            <option value={100}>100 Rows</option>
                        </select>
                    </div> */}
                    <div>
                        <table>
                            <tr>
                                <th>Amount</th>
                                <th>Account</th>
                                <th>IFCS</th>
                                <th>Order ID</th>
                            </tr>
                            {
                                props.getCsvFile.map((e, map) => {
                                    return (
                                        <tr>
                                            <td>Rs.{(e.amount / 100).toFixed(2)}</td>
                                            <td>{e.account}</td>
                                            <td>{e.ifsc}</td>
                                            <td>{e.order_id}</td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                props.getTransaction.map((e, map) => {
                                    let style = {
                                        color: 'white'
                                    }
                                    if (e.status) {
                                        style.color = 'green'
                                    } else {
                                        style.color = 'red'
                                    }
                                    return (
                                        <tr style={style}>
                                            <td>Rs.{(e.amount / 100).toFixed(2)}</td>
                                            <td>{e.account}</td>
                                            <td>{e.ifsc}</td>
                                            <td>{e.order_id}</td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>
            </div>
            {/* <style>
                {
                    `
                    .parentTransaction{
                        color: black;
                        border-radius: 30px 0px 0px 30px;
                        // height: 100vh;
                        background: #f7f6fa;
                    }
                    .childTransaction{
                        display:flex;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .childTransaction table,tr{
                        margin: 10px;
                        width: 100%;
                    }
                    label{
                        background: #ffffff;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 10px;
                        color: black;
                    }
                    td{
                        background: #ffffff;
                        padding: 20px;
                    }
                    table, th, td,tr{
                        border-spacing: 0;
                        border-collapse: collapse;
                        background: #ffffff;
                    }
                    select, input{
                        color: #6d7782;
                        padding: 5px;
                        border-radius: 10px;
                    }
                    .buttonset{
                        text-align: center;
                        padding: 10px;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .buttonset button{
                        margin: 10px;
                        background-color: #edefef;
                        text-align: center;
                        color: #d85539;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button{
                        background-color: #edefef;
                        text-align: center;
                        color: #6a7580;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .buttonset button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .blockside{
                        text-align: left;
                        background: #ffffff;
                        margin: 10px;
                        border-radius: 10px;
                        display: block;
                        // border: 1px solid #6a7580;
                    }
                    input{
                        color-scheme: light;
                    }
                    select, input{
                        background-color: white;
                        padding: 10px;
                        border: 1px solid #6a7580;
                    }
                    .tran_table div, table, tr, th,td{
                        padding: 3px;
                        background-color: #ffffff;
                    }
                    .tran_table div{
                        border-radius: 10px;
                    }
                    .tran_table{
                        overflow-y: scroll;
                        height: 50vh;
                        padding: 10px;
                        background-color: #ffffff;
                        text-align: center;
                        border-radius: 10px;
                        margin: 15px;
                        margin-top: 20px;
                    }
                    .tran_table::-webkit-scrollbar{
                        display: none;
                    }
                    .tran_table table{
                        width: 100%;   
                    }
                    .tran_table td,th{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    .tran_table th{
                        background-color: #d85539;
                        color: white;
                    }
                    .tran_table select{
                        padding: 5px;
                    }

                    .date{
                        margin-left: 40%;
                    }
                    .rowscount{
                        text-align: right;
                    }


                    .parentPopup {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.8);
                        color: white;
                        z-index: 1;
                    }
                    .childPopup{
                        color: white;
                        z-index: 0;
                        position: fixed; 
                        top: 50%; left:50%;
                        width: 70%;
                        text-align: center;
                        transform:translate(-50%,-50%);
                        border-radius: 10px;
                        display: flex;
                        text-align: left;
                        flex-shrink: 0;
                        // flex-wrap: wrap;
                        align-items: center;
                        margin: 10px;
                        padding: 10px;
                        background: #ffffff;
                        z-index: 2;
                    }
                    .childPopup table{
                        width: 100%;
                    }
                    .childPopup div, label{
                        background: #ffffff;
                    }
                    .close{
                        display: fixed;
                    }
                    .childPopup select{
                        color: black;
                        border-radius: 10px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }

                    `
                }
            </style> */}
        </>
    )
}


export default Bulk