import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import './App.css';
import Login from "./components/admin/pages/login";
import Dashboard from "./components/admin/pages/dashboard";
import Transaction from "./components/admin/pages/transaction";
import Merchant from "./components/admin/pages/merchant";
import Report from "./components/admin/pages/report";
import Account from "./components/admin/pages/account";
import Acquirer from "./components/admin/pages/acquirer";
import Bulk from "./components/admin/pages/bulk";
import Customer from "./components/admin/pages/customer";
import Logout from "./components/admin/pages/logout";
import Sidebar from "./components/admin/common/sidebar";
import { Outlet } from "react-router-dom";


import Cookies from "universal-cookie";
import Merchantlogin from "./components/merchant/pages/login";
import Merchantdashboard from "./components/merchant/pages/dashboard";
import Merchanttransaction from "./components/merchant/pages/transaction";
import Merchantcustomer from "./components/merchant/pages/customer";
import Merchantbulk from "./components/merchant/pages/bulk";
import Merchantreport from "./components/merchant/pages/report";
import Merchantaccount from "./components/merchant/pages/account";
import Merhcantlogout from "./components/merchant/pages/logout";
import MerchantSidebar from "./components/merchant/common/sidebar";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Credit from "./components/admin/pages/credit";
import Instant from "./components/admin/pages/instant";
import Merchantinstant from "./components/merchant/pages/instant";
import Merchantdeveloper from "./components/merchant/pages/developer";
import Cmsdownload from "./components/admin/pages/cmsdownload";
import Bulkcms from "./components/admin/pages/bulk_cms";



function App() {

  const cookie = new Cookies()




  const check_cookie = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_URL}/admin/cookie`, {
        method: 'POST',
        body: JSON.stringify({
          token: cookie.get('user')
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      })
      const data = await res.json()
      if (res.status === 200) {
        if (data.status === true) {
          return
        } else {
          //login
        }
      } else {
        //login
      }
    } catch (error) {
      //login
    }
  }
  const [getCsvFile, setCsvFile] = useState([]);
  const [getTransaction, setTransaction] = useState([]);
  const [getMaxvalue, setMaxvalue] = useState(100)
  const [getCurrentvalue, setCurrentvalue] = useState(0)


  const runwithme = async (getMerchant, arr, i) => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_URL}/admin/bulk_upload`, {
        method: 'POST',
        body: JSON.stringify({
          token: cookie.get('user'),
          merchant: getMerchant,
          data: arr,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      })
      if (resp.status === 200) {
        const dataset = await resp.json()
        if (dataset.status === true) {
          setCsvFile([])
          let arr1 = getTransaction
          arr1.push(...dataset.data.data)
          setTransaction(arr1)
          setCurrentvalue(getTransaction.length)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const upload_log = async (getMerchant) => {
    try {
      if (getMerchant) {
        setCurrentvalue(0)
        setMaxvalue(getCsvFile.length)
        for (let i = 0; i < getCsvFile.length; i) {
          if (getCsvFile.length - i > 10) {
            let arr = []
            arr.push(getCsvFile[i])
            arr.push(getCsvFile[i + 1])
            arr.push(getCsvFile[i + 2])
            arr.push(getCsvFile[i + 3])
            arr.push(getCsvFile[i + 4])
            arr.push(getCsvFile[i + 5])
            arr.push(getCsvFile[i + 6])
            arr.push(getCsvFile[i + 7])
            arr.push(getCsvFile[i + 8])
            arr.push(getCsvFile[i + 9])
            i++
            i++
            i++
            i++
            i++
            i++
            i++
            i++
            i++
            i++
            runwithme(getMerchant, arr, i)
          } else {
            runwithme(getMerchant, [getCsvFile[i]], i)
            i++
          }
        }
      } else {
        alert("select merchant")
      }

    } catch (error) {
      alert("error occured")
      console.log(error)
    }
  }


  const [getLoading, setLoading] = useState("");

  const PopupLoading = () => {
    if (getLoading !== "") {
      var class1;
      var class2;
      var labelval;
      var icon;
      if (getLoading === 'loading') {
        class1 = "loader-item loader-1"
        class2 = "material-icons loader-check-icon"
        labelval = "loading"
        setTimeout(clear_success_fail, 30000)
      }
      if (getLoading === 'success') {
        class1 = "loader-item loader-1 loader-success-completed"
        class2 = "material-icons loader-check-icon loader-check-icon-completed"
        labelval = "updated"
        icon = "check"
        setTimeout(clear_success_fail, 1000)
      }
      if (getLoading === 'fail') {
        class1 = "loader-item loader-1 loader-failure-completed"
        class2 = "material-icons loader-check-icon loader-failure-icon-completed"
        labelval = "failed"
        icon = "clear"
        setTimeout(clear_success_fail, 1000)
      }
      return (
        <>
          <div className="superparentPopup" onClick={(e) => { }}>
          </div>
          <div className="superchildPopup loadingpopup" style={{ width: "30%", height: "20%" }}>
            <div class="loader-wrapper">
              <div class={class1}>
                <i class={class2}>{icon}</i>
              </div>
              <div>
                <label>{labelval}</label>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  const clear_success_fail = async () => {
    try {
      if (getLoading == "success" || getLoading == "fail") {
        setLoading("")
      }
    } catch (error) {
      console.log(error)
    }
  }




  // hereitis

  const [getCsvFilemerchant, setCsvFilemerchant] = useState([]);
  const [getTransactionmerchant, setTransactionmerchant] = useState([]);
  const [getMaxvaluemerchant, setMaxvaluemerchant] = useState(100)
  const [getCurrentvaluemerchant, setCurrentvaluemerchant] = useState(0)


  const runwithme_merchant = async (arr, i) => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/bulk_upload`, {
        method: 'POST',
        body: JSON.stringify({
          token: cookie.get('merchant'),
          data: arr,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      })
      if (resp.status === 200) {
        const dataset = await resp.json()
        if (dataset.status === true) {
          setCsvFilemerchant([])
          let arr1 = getTransactionmerchant
          arr1.push(...dataset.data.data)
          setTransactionmerchant(arr1)
          setCurrentvaluemerchant(getTransactionmerchant.length)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const upload_log_merchant = async () => {
    try {
      setCurrentvaluemerchant(0)
      setMaxvaluemerchant(getCsvFilemerchant.length)
      for (let i = 0; i < getCsvFilemerchant.length; i) {
        if (getCsvFilemerchant.length - i > 10) {
          let arr = []
          arr.push(getCsvFilemerchant[i])
          arr.push(getCsvFilemerchant[i + 1])
          arr.push(getCsvFilemerchant[i + 2])
          arr.push(getCsvFilemerchant[i + 3])
          arr.push(getCsvFilemerchant[i + 4])
          arr.push(getCsvFilemerchant[i + 5])
          arr.push(getCsvFilemerchant[i + 6])
          arr.push(getCsvFilemerchant[i + 7])
          arr.push(getCsvFilemerchant[i + 8])
          arr.push(getCsvFilemerchant[i + 9])
          i++
          i++
          i++
          i++
          i++
          i++
          i++
          i++
          i++
          i++
          runwithme_merchant(arr, i)
        } else {
          runwithme_merchant([getCsvFilemerchant[i]], i)
          i++
        }
      }
    } catch (error) {
      alert("error occured")
      console.log(error)
    }
  }

  useEffect(() => {
    AOS.init();
  }, [])



  return (
    <>

      <Routes>
        <Route path="admin" element={<Login />} />
        <Route path="admin" element={
          <>
            <div className="cover">
              <Sidebar />
              <section className="home-section">
                <div className="rightdiv">
                  <PopupLoading />
                  <Outlet />
                </div>
              </section>
            </div>

          </>
        }>

          <Route path="dashboard" element={<Dashboard check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="merchant" element={<Merchant check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="bulk" element={<Bulk check_cookie={check_cookie} upload_log={upload_log} setCsvFile={setCsvFile} getCsvFile={getCsvFile} getTransaction={getTransaction} setTransaction={setTransaction} getMaxvalue={getMaxvalue} getCurrentvalue={getCurrentvalue} getRunning={(getCurrentvalue / getMaxvalue * 100).toFixed(2)} />} />
          <Route path="credit" element={<Credit check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="transaction" element={<Transaction check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="instant" element={<Instant check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="cms_data" element={<Cmsdownload check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="cms_upload" element={<Bulkcms upload_log={upload_log} setCsvFile={setCsvFile} getCsvFile={getCsvFile} getTransaction={getTransaction} setTransaction={setTransaction} getMaxvalue={getMaxvalue} getCurrentvalue={getCurrentvalue} getRunning={(getCurrentvalue / getMaxvalue * 100).toFixed(2)} />} />
          <Route path="customer" element={<Customer check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="report" element={<Report check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="acquirer" element={<Acquirer check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="account" element={<Account check_cookie={check_cookie} setLoading={setLoading} />} />
          <Route path="logout" element={<Logout check_cookie={check_cookie} />} />
        </Route>
        <Route path="merchant" element={<Merchantlogin />} />
        <Route path="merchant" element={
          <>
            <div className="cover">
              <MerchantSidebar />
              <section className="home-section">
                <div className="rightdiv">
                  <PopupLoading />
                  <Outlet />
                </div>
              </section>
            </div>

          </>
        }>
          <Route path="dashboard" element={<Merchantdashboard setLoading={setLoading} />} />
          <Route path="transaction" element={<Merchanttransaction setLoading={setLoading} />} />
          <Route path="instant" element={<Merchantinstant setLoading={setLoading} />} />
          <Route path="customer" element={<Merchantcustomer setLoading={setLoading} />} />
          <Route path="bulk" element={<Merchantbulk upload_log_merchant={upload_log_merchant} setCsvFilemerchant={setCsvFilemerchant} getCsvFilemerchant={getCsvFilemerchant} getTransactionmerchant={getTransactionmerchant} setTransactionmerchant={setTransactionmerchant} getMaxvaluemerchant={getMaxvaluemerchant} getCurrentvaluemerchant={getCurrentvaluemerchant} getRunningmerchant={(getCurrentvaluemerchant / getMaxvaluemerchant * 100).toFixed(2)} />} />
          <Route path="report" element={<Merchantreport setLoading={setLoading} />} />
          <Route path="account" element={<Merchantaccount setLoading={setLoading} />} />
          <Route path="developer" element={<Merchantdeveloper setLoading={setLoading} />} />
          <Route path="logout" element={<Merhcantlogout setLoading={setLoading} />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
