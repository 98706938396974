import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { LuRefreshCw } from "react-icons/lu";
import short from 'short-uuid';


const Customer = (props) => {
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getFilter, setFilter] = useState({})
    const [getRow, setRow] = useState(50)
    const [getCustomerlist, setCustomerlist] = useState([])
    const [getViewpopup, setViewpopup] = useState("")
    const [getcurCust, setcurCust] = useState({})
    const [getorderid, setorderid] = useState("")
    const [getoldorderid, setoldorderid] = useState("")
    const cookie = new Cookies()


    const gen_short_uid = async() => {
        const uuid_short = await short.generate()
        setorderid(uuid_short)
    }

    const get_merchant = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const create_customer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/create_customer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    name: document.getElementById("name").value,
                    account_number: document.getElementById("account").value,
                    ifsc_code: document.getElementById("ifsc").value,
                    merchant: document.getElementById("merchant").value
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_customer()
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const delete_customer = async (account_number) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/delete_customer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    account_number: account_number,
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_customer()
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_customer = async () => {
        try {
            setCustomerlist([{}])
            document.getElementById("loadinganimation").style.display = 'block'
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_customer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: getRow,
                    filter: ffilter
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setCustomerlist(dataset.data.data)
                    document.getElementById("loadinganimation").style.display = 'none'
                } else {
                    document.getElementById("loadinganimation").style.display = 'none'
                }
            } else {
                document.getElementById("loadinganimation").style.display = 'none'
            }

        } catch (error) {
            console.log(error)
        }
    }

    const customer_transfer = async () => {
        try {
            if (getorderid != "" && getorderid != null && getorderid != getoldorderid) {
                props.setLoading("loading")
                const resp = await fetch(`${process.env.REACT_APP_URL}/admin/customer_transfer`, {
                    method: 'POST',
                    body: JSON.stringify({
                        token: cookie.get('user'),
                        account: getViewpopup.split(".")[0],
                        merchant: getViewpopup.split(".")[1],
                        amount: document.getElementById("customer_amount").value * 100,
                        mode: document.getElementById("customer_mode").value,
                        order_id: document.getElementById("orderid").value
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
                setoldorderid(getorderid)
                if (resp.status === 200) {
                    const dataset = await resp.json()
                    if (dataset.status === true) {
                        setViewpopup("")
                        props.setLoading("success")
                    } else {
                        props.setLoading("fail")
                    }
                } else {
                    props.setLoading("fail")
                }
            } else {
                alert("generate new order ID")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const generate_order_id = async () => {
        const new_uuid = (await short.generate()).substring(0, 20)
        setorderid(new_uuid)
    }

    const PopupAcq = () => {
        if (getViewpopup) {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setViewpopup(""); setcurCust({}) }}>
                    </div>
                    <div className="childPopup">
                        <table>
                            <tr>
                                <td>
                                    <label>Name:</label>
                                </td>
                                <td>
                                    <input value={getcurCust.name} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Account Number:</label>
                                </td>
                                <td>
                                    <input value={getcurCust.account} disabled />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label>IFSC:</label>
                                </td>
                                <td>
                                    <input value={getcurCust.ifsc} disabled />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label>Order ID:</label>
                                </td>
                                <td>
                                    <input id="order_id" value={getorderid} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Mode:</label>
                                </td>
                                <td>
                                    <select id="customer_mode" >
                                        <option>Select Mode</option>
                                        <option value="IMPS">IMPS</option>
                                        <option value="RTGS">RTGS</option>
                                        <option value="NEFT">NEFT</option>
                                        <option value="UPI">UPI</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Amount:</label>
                                </td>
                                <td>
                                    <input id="customer_amount" placeholder="Enter Amount" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Order ID:</label>
                                </td>
                                <td>
                                    <input id="orderid" name="order_id" placeholder="generate Order ID" value={getorderid} />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button onClick={(e) => { generate_order_id() }} className='tablebtn'>Generate Order ID</button>
                                    <button onClick={(e) => { customer_transfer() }} className='tablebtn'>Transfer</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </>
            )
        }
    }



    useEffect(() => {
        // props.check_cookie()
        get_merchant()
        get_customer()
        get_acquirer()
    }, [])

    const [isActive, setActive] = useState(true);
    const [defaulttext, setDefaulttext] = useState("View Options")

    function showOptions() {
        let height_transition_div = document.getElementById('height_transition_div');
        height_transition_div.classList.toggle('show_div')
        setActive(!isActive)
        setDefaulttext(!defaulttext)
    }



    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside_low_top">
                    {/* <div className="right_dropdown_icon_container">
                        <button onClick={showOptions}>{defaulttext ? "View Options" : 'Close Options'} {isActive ? <IoIosArrowDropdown className='dropdown_icon' /> : <IoIosArrowDropup className='dropdown_icon' />}</button>
                    </div> */}

                    <div className='show_div_low_down' id='show_div_low_down'>
                        <div className="childTransaction">
                            {/* <PopupView /> */}
                            <table>
                                <tr>
                                    <td>
                                        <label>Account Number:</label>
                                    </td>
                                    <td>
                                        <input id="account" name="account_number" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Account Number" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>IFSC:</label>
                                    </td>
                                    <td>
                                        <input id="ifsc" name="ifsc_code" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter IFSC Code" />
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td>
                                        <label>Merchant:</label>
                                    </td>
                                    <td>
                                        <select id="merchant" name="merchant_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                            <option>Select Merchant</option>
                                            {
                                                getMerchantList.map((e, index) => {
                                                    return (
                                                        <option value={e.id}>{e.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Name:</label>
                                    </td>
                                    <td>
                                        <input id="name" name="name" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Customer Names" />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="buttonset clearbtndisplay">
                            <button onClick={(e) => { create_customer(); }}>Add Customer</button>
                            <button onClick={(e) => { get_customer() }}>Search Customer</button>
                            <button className='clearbtndisplay' >
                                <LuRefreshCw className='transferbtn' onClick={(e) => { get_customer() }} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="rowscount">
                    <select onChange={(e) => { setRow(e.target.value) }} onBlur={(e) => { get_customer() }}>
                        <option value={50}>50 Rows</option>
                        <option value={100}>100 Rows</option>
                        <option value={500}>500 Rows</option>
                        <option value={1000}>1000 Rows</option>
                        <option value={5000}>5000 Rows</option>

                    </select>
                </div>
                <div className="tran_table">

                    <div>
                        <table>
                            <tr>
                                <th>Name</th>
                                <th>Account</th>
                                <th>IFSC</th>
                                <th>Merchant</th>
                                <th>Acquirer</th>
                                <th>Transfer</th>
                                <th>Delete</th>
                            </tr>
                            {
                                getCustomerlist.map((e, index) => {
                                    return (
                                        <tr>

                                            <td>{e.name}</td>
                                            <td>{e.account}</td>
                                            <td>{e.ifsc}</td>
                                            {
                                                getMerchantList.map((ee, index) => {
                                                    if (e.merchant == ee.id) {
                                                        return (
                                                            <td>{ee.name}</td>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                getMerchantList.map((ee, index) => {
                                                    if (e.merchant == ee.id) {
                                                        for (let i = 0; i < getAcquirerlist.length; i++) {
                                                            if (getAcquirerlist[i].id == ee.acquirer) {
                                                                return (
                                                                    <td>{getAcquirerlist[i].name}</td>
                                                                )
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                            <td>
                                                <button className='clearbtndisplay' >
                                                    <FaMoneyBillTransfer className='transferbtn' onClick={(f) => {gen_short_uid(); setcurCust({ name: e.name, account: e.account, ifsc: e.ifsc }); setViewpopup(e.account + "." + e.merchant) }} />
                                                </button>
                                            </td>
                                            <td>
                                                <button className='clearbtndisplay'>
                                                    <MdDeleteForever className='deletebtn' onClick={(f) => { delete_customer(e.account); }} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                        <div style={{ display: "none" }} id='loadinganimation' class="loader-line"></div>
                    </div>
                </div>
                <div className="childAccount1">
                    <PopupAcq />
                </div>
            </div>
        </>
    )
}


export default Customer