import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { GiBrickWall } from "react-icons/gi";
import { LuRefreshCw } from "react-icons/lu";



const Merchant = (props) => {
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getMerchant, setMerchant] = useState({})
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getViewpopup, setViewpopup] = useState("")
    const [getViewpopuptrf, setViewpopuptrf] = useState("")
    const [getViewpopupip, setViewpopupip] = useState("")
    const [getMerchantIp, setMerchantIp] = useState([])
    const [getMerchantDetail, setMerchantDetail] = useState({})
    const [getRow, setRow] = useState(50)
    const [getFilter, setFilter] = useState({})
    const cookie = new Cookies()


    const create_merchant = async () => {
        try {
            props.setLoading("loading")
            if (getMerchant.password === getMerchant.cpassword) {
                const resp = await fetch(`${process.env.REACT_APP_URL}/admin/create_merchant`, {
                    method: 'POST',
                    body: JSON.stringify({
                        token: cookie.get('user'),
                        ...getMerchant
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
                if (resp.status === 200) {
                    const dataset = await resp.json()
                    if (dataset.status === true) {
                        get_merchant()
                        props.setLoading("success")
                    } else {
                        props.setLoading("fail")

                    }
                } else {
                    props.setLoading("fail")
                }
            }
        } catch (error) {
            console.log(error)
            props.setLoading("fail")
        }
    }


    const get_merchant = async () => {
        try {
            setMerchantList([{}])
            document.getElementById("loadinganimation").style.display = 'block'
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "") {
                        delete ffilter[clearfilter[i]]
                    }
                }
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: getRow,
                    filter: ffilter
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                    document.getElementById("loadinganimation").style.display = 'none'
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_merchant_details = async (merchant) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant_detail`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: merchant
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantDetail(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_merchant_ip = async (merchant) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant_ip`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: merchant
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantIp(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const modify_merchant = async (merchant) => {
        try {
            var temp = {}
            console.log(document.getElementById('flat_pop'))
            if (document.getElementById('imps1_pop').value !== "") {
                temp.imps1 = document.getElementById('imps1_pop').value * 100
            }
            if (document.getElementById('imps2_pop').value !== "") {
                temp.imps2 = document.getElementById('imps2_pop').value * 100
            }
            if (document.getElementById('imps3_pop').value !== "") {
                temp.imps3 = document.getElementById('imps3_pop').value * 100
            }
            if (document.getElementById('rtgs_pop').value !== "") {
                temp.rtgs = document.getElementById('rtgs_pop').value * 100
            }
            if (document.getElementById('neft_pop').value !== "") {
                temp.neft = document.getElementById('neft_pop').value * 100
            }
            if (document.getElementById('upi_pop').value !== "") {
                temp.upi = document.getElementById('upi_pop').value * 100
            }
            if (document.getElementById('webhook_pop').value !== "") {
                temp.webhook = document.getElementById('webhook_pop').value
            }
            if (document.getElementById('flat_pop').value !== "") {
                temp.flat = document.getElementById('flat_pop').value
            }
            if (document.getElementById('acquirer_pop').value !== "") {
                temp.acquirer = document.getElementById('acquirer_pop').value
            }
            if (document.getElementById('password_pop').value !== "") {
                modify_merchant_password(document.getElementById('password_pop').value, merchant)
            }
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/modify_merchant`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: merchant,
                    updates: temp
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_merchant_details(merchant)
                    get_merchant()
                    props.setLoading("success")
                    // setViewpopup("")
                    // setViewpopup(merchant)
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
            props.setLoading("fail")
        }
    }

    const modify_merchant_status = async (updates, merchant) => {
        try {
            props.setLoading("loading")
            console.log(updates)
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/modify_merchant`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: merchant,
                    updates: updates
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_merchant()
                    props.setLoading("success")
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
            props.setLoading("fail")
        }
    }

    const modify_merchant_password = async (passwordhere, merchant) => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/modify_merchant_password`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: merchant,
                    password: passwordhere
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_merchant_details(merchant)
                    props.setLoading("success")

                    // setViewpopup("")
                    // setViewpopup(merchant)
                } else {
                    props.setLoading("fail")

                }
            } else {
                props.setLoading("fail")

            }
        } catch (error) {
            console.log(error)
            props.setLoading("fail")
        }
    }


    const changebal = async () => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/wallet_transaction`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: getViewpopuptrf,
                    amount: document.getElementById("wallet_amount").value * 100,
                    transaction_type: document.getElementById("transaction_type").value
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_merchant()
                    props.setLoading("success")
                    // setViewpopup("")
                    // setViewpopup(merchant)
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
            props.setLoading("fail")
        }
    }

    const changeip = async () => {
        try {
            props.setLoading("loading")
            let arr = []
            for (let i = 0; i < getMerchantIp.length; i++) {
                if (getMerchantIp[i] != "" && getMerchantIp[i] != "delete") {
                    arr.push(getMerchantIp[i])
                }
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/modify_merchant_ip`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: getViewpopupip,
                    ip: arr
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    props.setLoading("success")
                    // setViewpopup("")
                    // setViewpopup(merchant)
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
            props.setLoading("fail")
        }
    }




    const PopupAcq = () => {
        if (getViewpopup) {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setViewpopup("") }}>
                    </div>
                    <div className="childPopup">
                        <table>
                            <tr>
                                <td>
                                    <label>Name:</label>
                                </td>
                                <td>
                                    <input name="name" value={getMerchantDetail.name} disabled />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label>Acquirer:</label>
                                </td>
                                <td>
                                    <select id="acquirer_pop" >
                                        {
                                            getAcquirerlist.map((e, index) => {
                                                if (getMerchantDetail.acquirer === e.id)
                                                    return (
                                                        <option value={e.id}>{e.name}</option>
                                                    )
                                            })
                                        }
                                        {
                                            getAcquirerlist.map((e, index) => {
                                                if (getMerchantDetail.acquirer !== e.id) {
                                                    return (
                                                        <option value={e.id}>{e.name}</option>
                                                    )
                                                }
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Charge Type:</label>
                                </td>
                                <td>
                                    {
                                        getMerchantDetail.flat ?
                                            <select id="flat_pop" >
                                                <option value={true}>Flat</option><option value={false}>Percentage</option>
                                            </select>
                                            :
                                            <select id="flat_pop" >
                                                <option value={false}>Percentage</option><option value={true}>Flat</option>
                                            </select>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>IMPS:</label>
                                </td>
                                <td>
                                    <input id="imps1_pop" placeholder={getMerchantDetail.imps1} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label>IMPS:</label>
                                </td>
                                <td>
                                    <input id="imps2_pop" placeholder={getMerchantDetail.imps2} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label>IMPS:</label>
                                </td>
                                <td>
                                    <input id="imps3_pop" placeholder={getMerchantDetail.imps3} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>MID:</label>
                                </td>
                                <td>
                                    <input value={getMerchantDetail.mid} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Req Enc key:</label>
                                </td>
                                <td>
                                    <input value={getMerchantDetail.enc_key} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Req Salt key:</label>
                                </td>
                                <td>
                                    <input value={getMerchantDetail.enc_salt} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td>
                                    <label>Email:</label>
                                </td>
                                <td>
                                    <input name="email" value={getMerchantDetail.email} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Phone:</label>
                                </td>
                                <td>
                                    <input name="phone" value={getMerchantDetail.phone} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>New Password:</label>
                                </td>
                                <td>
                                    <input id="password_pop" onBlur={(e) => { modify_merchant_password(e.target.value, getViewpopup) }} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>RTGS</label>
                                </td>
                                <td>
                                    <input id="rtgs_pop" placeholder={getMerchantDetail.rtgs} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>NEFT:</label>
                                </td>
                                <td>
                                    <input id="neft_pop" placeholder={getMerchantDetail.neft} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>UPI:</label>
                                </td>
                                <td>
                                    <input id="upi_pop" placeholder={getMerchantDetail.upi} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Webhook:</label>
                                </td>
                                <td>
                                    <input id="webhook_pop" placeholder={getMerchantDetail.webhook}></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Res Enc key:</label>
                                </td>
                                <td>
                                    <input value={getMerchantDetail.dec_key} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Res Salt key:</label>
                                </td>
                                <td>
                                    <input value={getMerchantDetail.dec_salt} disabled />
                                </td>
                            </tr>
                            <tr>
                                <button name={getMerchantDetail.id} onClick={(e) => { modify_merchant(e.target.name) }}>Save</button>
                            </tr>
                        </table>
                    </div>
                </>
            )
        }
    }


    const PopupTrf = () => {
        if (getViewpopuptrf) {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setViewpopuptrf("") }}>
                    </div>
                    <div className="childPopup">
                        <table>
                            <tr>
                                <td>
                                    <label>Merchant</label>
                                </td>
                                <td>
                                    <input value={getMerchantDetail.name} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Acquirer</label>
                                </td>
                                <td>
                                    {
                                        getAcquirerlist.map((e, index) => {
                                            if (e.id == getMerchantDetail.acquirer) {
                                                return <select disabled><option value={e.name} >{e.name}</option></select>
                                            }
                                        })
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Transaction Type:</label>
                                </td>
                                <td>
                                    <select id="transaction_type" name="transaction" >
                                        <option value="credit">Credit</option>
                                        <option value="debit">Debit</option>
                                        <option value="hold">Hold</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Amount:</label>
                                </td>
                                <td>
                                    <input id="wallet_amount" name="amount" placeholder="Enter Amount" />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button onClick={(e) => { changebal() }} className='tablebtn'>Submit</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </>
            )
        }
    }




    const PopupIp = () => {
        if (getViewpopupip) {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setViewpopupip("") }}>
                    </div>
                    <div className="childPopup">
                        <table>
                            <tr>
                                <td>
                                    <label>IP List:</label>
                                </td>
                                <td>
                                    <button onClick={(e) => { setMerchantIp([...getMerchantIp, ""]) }} className='tablebtn'>Add</button>
                                </td>
                            </tr>
                            {
                                getMerchantIp.map((e, index) => {
                                    if (e === "delete") {

                                    } else {
                                        return (
                                            <tr>
                                                <td>
                                                    <input name={index} placeholder={e} onBlur={(e) => { getMerchantIp[e.target.name] = e.target.value; console.log(getMerchantIp); setMerchantIp([...getMerchantIp]) }} />
                                                </td>
                                                <td>
                                                    <button name={index} onClick={(e) => { getMerchantIp[e.target.name] = "delete"; setMerchantIp([...getMerchantIp]) }} className='tablebtn'>Delete</button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })
                            }

                            <tr>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    <button onClick={(e) => { changeip() }} className='tablebtn'>Save</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </>
            )
        }
    }

    useEffect(() => {
        // props.check_cookie()
        get_acquirer()
        get_merchant()
    }, [])

    const [isActive, setActive] = useState(true);
    const [defaulttext, setDefaulttext] = useState("View Options")

    function showOptions() {
        let height_transition_div = document.getElementById('height_transition_div');
        height_transition_div.classList.toggle('show_div')
        setActive(!isActive)
        setDefaulttext(!defaulttext)
    }


    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="right_dropdown_icon_container">
                        <button className='clearbtndisplay' >
                            <LuRefreshCw className='transferbtn' onClick={(e) => { get_merchant() }} />
                        </button>
                        <button onClick={showOptions}>{defaulttext ? "View Options" : 'Close Options'} {isActive ? <IoIosArrowDropdown className='dropdown_icon' /> : <IoIosArrowDropup className='dropdown_icon' />}</button>
                    </div>
                    <div className='height_transition_div' id='height_transition_div'>
                        <div className="childTransaction">
                            {/* <PopupView /> */}

                            <table>
                                <tr>
                                    <td>
                                        <label>Name:</label>
                                    </td>
                                    <td>
                                        <input name="name" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Name" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Acquirer:</label>
                                    </td>
                                    <td>
                                        <select name="acquirer" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                            <option>Select Acquirer</option>
                                            {
                                                getAcquirerlist.map((e, index) => {
                                                    return (
                                                        <option value={e.id}>{e.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Charge Type:</label>
                                    </td>
                                    <td>
                                        <select name="flat" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                            <option>Charge Type</option>
                                            <option value={true}>Flat</option>
                                            <option value={false}>Perentage</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>MID:</label>
                                    </td>
                                    <td>
                                        <input name="mid" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="mid" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>IMPS:</label>
                                    </td>
                                    <td>
                                        <input name="imps1" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value * 100 }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value * 100 } }) }} placeholder="Rs 0 - 1,000" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>IMPS:</label>
                                    </td>
                                    <td>
                                        <input name="imps2" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value * 100 }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value * 100 } }) }} placeholder="Rs. 1,001 - 25,000" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>IMPS:</label>
                                    </td>
                                    <td>
                                        <input name="imps3" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value * 100 }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value * 100 } }) }} placeholder="Rs. 25,001 - 5,00,000" />
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td>
                                        <label>Phone:</label>
                                    </td>
                                    <td>
                                        <input name="phone" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Phone" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Email:</label>
                                    </td>
                                    <td>
                                        <input name="email" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Email" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Password:</label>
                                    </td>
                                    <td>
                                        <input name="password" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }) }} placeholder="Enter Password" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Confirm Password:</label>
                                    </td>
                                    <td>
                                        <input name="cpassword" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }) }} placeholder="Enter Password Again" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>RTGS</label>
                                    </td>
                                    <td>
                                        <input name="rtgs" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value * 100 }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value * 100 } }) }} placeholder="RTGS charges" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>NEFT:</label>
                                    </td>
                                    <td>
                                        <input name="neft" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value * 100 }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value * 100 } }) }} placeholder="NEFT charges" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>UPI:</label>
                                    </td>
                                    <td>
                                        <input name="upi" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value * 100 }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value * 100 } }) }} placeholder="UPI charges" />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="buttonset">
                            <button onClick={(e) => { create_merchant() }}>Create Merchant</button>


                            <button className="animatebuton" onClick={(e) => { get_merchant() }}>Search Merchant</button>

                        </div>
                    </div>
                </div>
                <div className="rowscount">
                    <select onBlur={(e) => { get_merchant() }} onChange={(e) => { setRow(e.target.value); }}>
                        <option value={50}>50 Rows</option>
                        <option value={100}>100 Rows</option>
                        <option value={500}>500 Rows</option>
                        <option value={1000}>1000 Rows</option>
                        <option value={5000}>5000 Rows</option>
                    </select>
                </div>
                <div className="tran_table">
                    <div>
                        <table>
                            <tr>
                                <th>NAME</th>
                                <th>EMAIL</th>
                                <th>BALANCE</th>
                                <th>HOLD</th>
                                <th>ROUTE</th>
                                <th>STATUS</th>
                                <th >VIEW</th>
                                <th>TRANSFER</th>
                                <th>IPs</th>
                            </tr>
                            {
                                getMerchantList.map((e, index) => {
                                    var customclass = "";
                                    if (index % 2 === 0) {
                                        customclass = "darkishere"
                                    }
                                    return (
                                        <tr className={`minimum_width_ex ${customclass}`}>
                                            <td>{e.name}</td>
                                            <td>{e.email}</td>
                                            <td>₹{parseFloat(e.balance).toLocaleString("en-IN")}</td>
                                            <td>₹{parseFloat(e.limit).toLocaleString("en-IN")}</td>
                                            <td>
                                                {
                                                    getAcquirerlist.map((ee, index) => {
                                                        if (ee.id == e.acquirer) {
                                                            return ee.name
                                                        }
                                                    })
                                                }
                                            </td>
                                            <td className='inputonoff'>
                                                {
                                                    e.status ?
                                                        <input name={e.id} type="checkbox" checked={e.status} onClick={(e) => { modify_merchant_status({ status: false }, e.target.name) }}></input>
                                                        :
                                                        <input name={e.id} type="checkbox" checked={e.status} onClick={(e) => { modify_merchant_status({ status: true }, e.target.name) }}></input>

                                                }
                                            </td>
                                            <td>
                                                <button className='clearbtndisplay' >
                                                    <FaEye className='transferbtn' onClick={(f) => { setViewpopup(e.id); get_acquirer(); get_merchant_details(e.id); }} />
                                                </button>
                                            </td>
                                            <td>
                                                <button className='clearbtndisplay' >
                                                    <FaMoneyBillTransfer className='transferbtn' onClick={(f) => { setViewpopuptrf(e.id); get_merchant_details(e.id); }} />
                                                </button>
                                            </td>
                                            <td>
                                                <button className='clearbtndisplay' >
                                                    <GiBrickWall className='transferbtn' onClick={(f) => { setViewpopupip(e.id); get_merchant_ip(e.id); }} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                        <div style={{ display: "none" }} id='loadinganimation' class="loader-line"></div>
                    </div>
                </div>
                <div className="childAccount1">
                    < PopupAcq />
                    <PopupTrf />
                    <PopupIp />
                </div>
            </div>

        </>
    )
}


export default Merchant