import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css';
import { LuRefreshCw } from "react-icons/lu";
import short from 'short-uuid';


const Instant = (props) => {
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getFilter, setFilter] = useState({})
    const [getRow, setRow] = useState(50)
    const [getTransaction, setTransaction] = useState([{}])
    const [getUpdateStatus, setUpdateStatus] = useState("")
    const [getUpdateTxn, setUpdateTxn] = useState("")
    const [getorderid, setorderid] = useState("")
    const [getoldorderid, setoldorderid] = useState("")

    const cookie = new Cookies()




    const get_merchant = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const manual_status_change = async (txn_id, status) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/manual_status_change`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    txn_id: getUpdateTxn,
                    status: getUpdateStatus
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_transaction()
                } else {
                    alert("update Failed")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const instant_transfer = async () => {
        try {
            if (getorderid != "" && getorderid != null && getorderid != getoldorderid) {
                props.setLoading("loading")
                var name = ""
                if (document.getElementById("name").value !== "") {
                    name = document.getElementById("name").value
                } else {
                    name = new Date().getTime()
                }
                const resp = await fetch(`${process.env.REACT_APP_URL}/admin/instant_transfer`, {
                    method: 'POST',
                    body: JSON.stringify({
                        token: cookie.get('user'),
                        name: name,
                        account_number: document.getElementById("account").value,
                        mode: document.getElementById("mode").value,
                        ifsc_code: document.getElementById("ifsc").value,
                        merchant: document.getElementById("merchant").value,
                        amount: document.getElementById("amount").value * 100,
                        order_id: document.getElementById("orderid").value                        
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
                setoldorderid(getorderid)
                if (resp.status === 200) {
                    const dataset = await resp.json()
                    if (dataset.status === true) {
                        get_transaction()
                        props.setLoading("success")
                    } else {
                        props.setLoading("fail")
                    }
                } else {
                    props.setLoading("fail")
                }
            } else {
                alert("generate new order ID")
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_transaction = async () => {
        try {
            setTransaction([{}])
            document.getElementById("loadinganimation").style.display = 'block'
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_transaction`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: getRow,
                    filter: ffilter
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransaction(dataset.data.data)
                    document.getElementById("loadinganimation").style.display = 'none'
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const check_status_update = async (order_id) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/check_status_update`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    order_id: order_id
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    let arr = getTransaction
                    for (let i = 0; i < arr.length; i++) {
                        {
                            if (arr[i].order_id === dataset.data.data[0].order_id) {
                                console.log("it ran")
                                console.log(arr)
                                arr[i].status = dataset.data.data[0].status
                                arr[i].updated_time = dataset.data.data[0].updated_time
                                arr[i].rrn = dataset.data.data[0].rrn
                                arr[i].error = dataset.data.data[0].error
                                console.log(arr)
                                setTransaction([...arr])
                                break;
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const generate_order_id = async () => {
        const new_uuid = (await short.generate()).substring(0, 20)
        setorderid(new_uuid)
    }


    useEffect(() => {
        // props.check_cookie()
        get_acquirer()
        get_merchant()
        get_transaction()

    }, [])

    const [isActive, setActive] = useState(true);
    const [defaulttext, setDefaulttext] = useState("View Options")

    function showOptions() {
        let height_transition_div = document.getElementById('height_transition_div');
        height_transition_div.classList.toggle('show_div')
        setActive(!isActive)
        setDefaulttext(!defaulttext)
    }


    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside_low_top" >
                    {/* <div className="right_dropdown_icon_container">
                        <button onClick={showOptions}>{defaulttext ? "View Options" : 'Close Options'} {isActive ? <IoIosArrowDropdown className='dropdown_icon' /> : <IoIosArrowDropup className='dropdown_icon' />}</button>
                    </div> */}


                    <div className='show_div_low_down' id='show_div_low_down' >
                        <div className="childTransaction">
                            {/* <PopupView /> */}
                            <table>
                                <tr>
                                    <td>
                                        <label>Mode:<text style={{ color: "red" }}>*</text></label>
                                    </td>
                                    <td>
                                        <select id="mode" >
                                            <option>Select Mode</option>
                                            <option value="IMPS">IMPS</option>
                                            <option value="RTGS">RTGS</option>
                                            <option value="NEFT">NEFT</option>
                                            <option value="UPI">UPI</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Account Number:<text style={{ color: "red" }}>*</text></label>
                                    </td>
                                    <td>
                                        <input id="account" name="account_number" placeholder="Enter Account Number" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>IFSC:<text style={{ color: "red" }}>*</text></label>
                                    </td>
                                    <td>
                                        <input id="ifsc" name="ifsc_code" placeholder="Enter IFSC Code" />
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td>
                                        <label>Merchant:<text style={{ color: "red" }}>*</text></label>
                                    </td>
                                    <td>
                                        <select id="merchant" name="merchant_id" >
                                            <option>Select Merchant</option>
                                            {
                                                getMerchantList.map((e, index) => {
                                                    return (
                                                        <option value={e.id}>{e.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Amount:<text style={{ color: "red" }}>*</text></label>
                                    </td>
                                    <td>
                                        <input id="amount" name="amount" placeholder="Enter Customer Names" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Name:</label>
                                    </td>
                                    <td>
                                        <input id="name" name="name" placeholder="Enter Customer Names" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Order ID:</label>
                                    </td>
                                    <td>
                                        <input id="orderid" name="order_id" placeholder="generate Order ID" value={getorderid} />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="buttonset clearbtndisplay">
                            <button onClick={(e) => { generate_order_id() }}>Generate Order ID</button>
                            <button onClick={(e) => { instant_transfer(); }}>Instant Transfer</button>
                            <button className='clearbtndisplay' >
                                <LuRefreshCw className='transferbtn' onClick={(e) => { get_transaction() }} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="rowscount">
                    <select onBlur={(e) => { get_transaction(); }} onChange={(e) => { setRow(e.target.value); }}>
                        <option value={50}>50 Rows</option>
                        <option value={100}>100 Rows</option>
                        <option value={500}>500 Rows</option>
                        <option value={1000}>1000 Rows</option>
                        <option value={5000}>5000 Rows</option>
                    </select>
                </div>
                <div className="tran_table">
                    <div>
                        <table >
                            <tr className='minimum_width_ex'>
                                <th>Amount</th>
                                <th>Merchant</th>
                                <th>Acquirer</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>Service</th>
                                <th>Account</th>
                                <th>Order ID</th>
                                <th>UTR/RRN</th>
                                <th>Date & Time</th>
                            </tr>
                            {
                                getTransaction.map((e, index) => {
                                    const updatetimeorg = new Date(e.updated_time)
                                    const createtimeorg = new Date(e.created_time)
                                    const updatetime = `${(updatetimeorg.getDate() > 9) ? updatetimeorg.getDate() : '0' + updatetimeorg.getDate()}/${(updatetimeorg.getMonth() > 8) ? updatetimeorg.getMonth() + 1 : '0' + (updatetimeorg.getMonth() + 1)}/${updatetimeorg.getFullYear()} ${updatetimeorg.getHours() > 9 ? updatetimeorg.getHours() : '0' + updatetimeorg.getHours()}:${updatetimeorg.getMinutes() > 9 ? updatetimeorg.getMinutes() : '0' + updatetimeorg.getMinutes()}:${updatetimeorg.getSeconds() > 9 ? updatetimeorg.getSeconds() : '0' + updatetimeorg.getSeconds()}`
                                    const createtime = `${(createtimeorg.getDate() > 9) ? createtimeorg.getDate() : '0' + createtimeorg.getDate()}/${(createtimeorg.getMonth() > 8) ? createtimeorg.getMonth() + 1 : '0' + (createtimeorg.getMonth() + 1)}/${createtimeorg.getFullYear()} ${createtimeorg.getHours() > 9 ? createtimeorg.getHours() : '0' + createtimeorg.getHours()}:${createtimeorg.getMinutes() > 9 ? createtimeorg.getMinutes() : '0' + createtimeorg.getMinutes()}:${createtimeorg.getSeconds() > 9 ? createtimeorg.getSeconds() : '0' + createtimeorg.getSeconds()}`

                                    var customclass = "";
                                    if (index % 2 === 0) {
                                        customclass = "darkishere"
                                    }
                                    if (e.amount) {
                                        return (
                                            <tr className={`minimum_width_ex ${customclass}`}>
                                                <td >₹{((e.amount / 100)).toLocaleString("en-IN")}</td>
                                                <td>
                                                    {
                                                        getMerchantList.map((ee, index) => {
                                                            if (ee.id == e.merchant_id) {
                                                                return ee.name
                                                            }
                                                        })
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        getAcquirerlist.map((ee, index) => {
                                                            if (ee.id == e.route) {
                                                                return ee.name
                                                            } else if ((e.service === "Admin Debit" || e.service === "Admin Credit") && getAcquirerlist.length - 1 == index) {
                                                                return "ADMIN";
                                                            }
                                                        })
                                                    }
                                                </td>
                                                <td>{e.status}</td>
                                                <td>{e.type}</td>
                                                <td>{e.service}</td>
                                                <td>{e.account}</td>
                                                <td>{e.order_id}</td>
                                                <td>{e.rrn}</td>
                                                <td style={{ fontSize: "11px" }}>{updatetime}<br />{createtime}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </table>
                        <div style={{ display: "none" }} id='loadinganimation' class="loader-line"></div>
                    </div>
                </div>
                <div className="childAccount1">
                </div>
            </div>
            {/* <style>
                {
                    `
                    .parentTransaction{
                        color: black;
                        background: #f7f6fa;
                    }
                    .childTransaction{
                        display:flex;
                        background: #ffffff;
                    }
                    .childTransaction table,tr{
                        margin: 10px;
                        width: 100%;
                    }
                    label{
                        background: #ffffff;
                        font-size: 17px;
                        margin: 10px;
                        color: #adadad;
                    }
                    td{
                        background: #ffffff;
                        padding: 20px;
                    }
                    table, th, td,tr{
                        border-spacing: 0;
                        border-collapse: collapse;
                        background: #ffffff;
                    }
                    select, input{
                        color: #6d7782;
                        padding: 3px 5px;
                        border-radius: 4px;
                        // border: 1.5px solid #adadad;
                        text-align: center;
                        min-width: 177px;
                    }

                    input:focus {
                        outline: none;
                        border: 2px solid #a74dfd;
                    }

                    select:focus {
                        outline: none;
                    }

                    .buttonset{
                        text-align: center;
                        padding: 10px;
                        background: #ffffff;
                    }

                    button{
                        margin: 10px;
                        background: linear-gradient(to bottom, #a74dfd, #9c40fe, #8a34fe);
                        text-align: center;
                        color: white;
                        font-size: 16px;
                        padding: 8px 10px;
                        border-radius: 4px;
                        box-shadow: 3px 4px 12px #8a34fe;
                        border: none;
                        outline: none;
                    }
    
                    button:hover{
                        color: #cccaca;
                        cursor: pointer
                    }

                    .tablebtn {
                        padding: 4px 6px;
                    }
                    .blockside{
                        text-align: left;
                        background: #ffffff;
                        margin: 10px auto 10px auto;
                        display: block;
                        // border: 1px solid #6a7580;
                        max-width: 98%;
                    }
                    input{
                        color-scheme: light;
                    }
                    select, input{
                        background-color: white;
                        padding: 10px;
                        border: 1px solid #6a7580;
                    }
                    .tran_table div, table, tr, th,td{
                        padding: 3px;
                        background-color: #ffffff;
                    }
                    .tran_table{
                        overflow-y: scroll;
                        padding: 10px;
                        background-color: #ffffff;
                        text-align: center;
                        margin-top: 2px;
                        max-width: 98%;
                        margin: 2px auto 2px auto;
                    }
                    .tran_table::-webkit-scrollbar{
                        display: none;
                    }
                    .tran_table table{
                        width: 100%;   
                        padding: 20px 0px;
                    }
                    .tran_table td,th{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    .tran_table th{
                        background-color:  #8a34fe;
                        color: white;
                        padding: 6px 0px;
                        box-shadow: 3px 4px 12px #8a34fe;
                    }

                    .tran_table tr:nth-child(2) {
                        margin-top: 20px;
                    }
                    .tran_table select{
                        padding: 5px;
                    }

                    .date{
                        margin-left: 40%;
                    }
                    .rowscount{
                        text-align: right;
                        margin: 30px 20px 0px 0px;
                    }


                    .parentPopup {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.8);
                        color: white;
                        z-index: 1;
                    }
                    .childPopup{
                        color: white;
                        z-index: 0;
                        position: fixed; 
                        top: 50%; left:50%;
                        width: 70%;
                        text-align: center;
                        transform:translate(-50%,-50%);
                        border-radius: 10px;
                        display: flex;
                        text-align: left;
                        flex-shrink: 0;
                        // flex-wrap: wrap;
                        align-items: center;
                        margin: 10px;
                        padding: 10px;
                        background: #ffffff;
                        z-index: 2;
                    }
                    .childPopup table{
                        width: 100%;
                    }
                    .childPopup div, label{
                        background: #ffffff;
                    }
                    .close{
                        display: fixed;
                    }
                    .childPopup select{
                        color: black;
                        border-radius: 10px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }
                    `
                }
                `
            </style> */}
        </>
    )
}


export default Instant