import { NavLink } from "react-router-dom";
import Logo from './logo128.png';
import { MdSpaceDashboard, MdAccountCircle } from "react-icons/md";
import { FaHouseUser } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { LiaMailBulkSolid } from "react-icons/lia";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { CiLogout } from "react-icons/ci";
import { FaMoneyBillTransfer } from "react-icons/fa6";

const MerchantSidebar = () => {

    return (
        <>
            <div className="sideBar">
                <div className="profile">
                    <img src={Logo} alt="Couldn't load image" />
                    <span>Payout</span>
                </div>
                <li>
                    <NavLink to="/merchant/dashboard">
                        <MdSpaceDashboard className="nav_icon" />
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/merchant/transaction">
                        <GrTransaction className="nav_icon" />
                        <span>Transaction</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/merchant/instant">
                        <FaMoneyBillTransfer className="nav_icon" />
                        <span>Instant Transfer</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/merchant/customer">
                        <FaHouseUser className="nav_icon" />
                        <span>Customer</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/merchant/bulk">
                        <LiaMailBulkSolid className="nav_icon" />
                        <span>Bulk Upload</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/merchant/report">
                        <HiOutlineDocumentReport className="nav_icon" />
                        <span>Report</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/merchant/account">
                        <MdAccountCircle className="nav_icon" />
                        <span>My Account</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/merchant/logout">
                        <CiLogout className="nav_icon" />
                        <span>Log out</span></NavLink>
                </li>
            </div>

            <style>
            {
                    `
                    .sideBar{
                        // position: absolute;
                        // top: 0;
                        // left: 0;
                        width: 260px;
                        min-height: 100%;
                        padding: 20px 0;
                        text-align:center;
                        // background-color: #edeae7;
                        background: #ffffff;
                        overflow-y: scroll;
                        overflow-x: hidden;
                    }
                    .sideBar::-webkit-scrollbar {
                        width: 3px;
                      }
                    .sideBar .profile {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 20px;
                        margin-left: 40px;

                        img {
                            max-width: 50px;
                            margin-right: 10px;
                        }

                        span {
                            font-size: 24px;
                            font-weight: 600;
                        }
                    }

                    
                    .sideBar li a{
                        text-decoration: none;
                        color: black;
                        display: flex;
                        align-items: center;
                        // border-radius: 10px;
                        padding: 2px;
                        font-size: 16px;
                        width: 70%;
                        font-weight: 600;
                        padding: 10px;
                    }

                    .sideBar li a .nav_icon {
                        color: black;
                        font-size: 28px;
                        // margin-bottom: -2px;
                        text-align: center;
                        font-weight" 600;
                        line-height: 50px;
                        margin-right: 5px;
                    }
                    .sideBar li{
                        text-align: left;
                        list-style-type: none;
                        margin: 5px;
                        border-radius: 10px;
                        color: black;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 3px;
                        position: relative;
                        margin: 10px 0;
                    }
                    .sideBar li:hover a{
                        background: #d85539;
                        color: white;
                    }
                    .sideBar li .active{
                        background: #d85539;
                        color: white;
                    }
                    
                    .sideBar li:hover .nav_icon{
                        color: white;
                    }
                    
                    .sideBar li .active .nav_icon {
                        color: white;
                    }

                    .sideBar .active li {
                        border-left: 2px solid #9837fe;
                    }

                   
                    .profile p{
                        color: black;
                        margin-bottom: 50px;
                        font-size: 32px;
                        font-weight: 600;
                    }
                    `
                }
            </style>
        </>
    )
}

export default MerchantSidebar;