import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";




const Merchant = (props) => {
    const [getRoute, setRoute] = useState([{}])
    const [getAcquirer, setAcquirer] = useState({ api_key: "empty", username: "empty", password: "empty" })
    const [getsearchAcquirer, setsearchAcquirer] = useState()
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getViewpopup, setViewpopup] = useState("")
    const [getAcquirerDetail, setAcquirerDetail] = useState({})
    const [getLastname, setLastname] = useState("")
    const [getRow, setRow] = useState(10)
    const [getFilter, setFilter] = useState({})
    const cookie = new Cookies()


    const create_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/create_acquirer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    ...getAcquirer
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setRoute(dataset.data.data)
                    get_acquirer()
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_route = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_route`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setRoute(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_balance = async () => {
        try {
            console.log("herehit siesr")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_balance`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_acquirer = async () => {
        try {
            document.getElementById("loadinganimation").style.display = 'block'
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    filter: getsearchAcquirer
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                    document.getElementById("loadinganimation").style.display = 'none'
                }
            }
        } catch (error) {
            console.log(error)
        }
    }





    const modify_acquirer = async (acquirer) => {
        try {
            props.setLoading("loading")
            var temp = {}
            console.log(document.getElementById('flat_pop'))
            if (document.getElementById('imps1_pop').value !== "") {
                temp.imps1 = document.getElementById('imps1_pop').value * 100
            }
            if (document.getElementById('imps2_pop').value !== "") {
                temp.imps2 = document.getElementById('imps2_pop').value * 100
            }
            if (document.getElementById('imps3_pop').value !== "") {
                temp.imps3 = document.getElementById('imps3_pop').value * 100
            }
            if (document.getElementById('rtgs_pop').value !== "") {
                temp.rtgs = document.getElementById('rtgs_pop').value * 100
            }
            if (document.getElementById('neft_pop').value !== "") {
                temp.neft = document.getElementById('neft_pop').value * 100
            }
            if (document.getElementById('upi_pop').value !== "") {
                temp.upi = document.getElementById('upi_pop').value * 100
            }
            if (document.getElementById('rate_pop').value !== "") {
                temp.rate = document.getElementById('rate_pop').value
            }
            if (document.getElementById('username_pop').value !== "") {
                temp.username = document.getElementById('username_pop').value
            }
            if (document.getElementById('password_pop').value !== "") {
                temp.password = document.getElementById('password_pop').value
            }
            if (document.getElementById('api_key_pop').value !== "") {
                temp.api_key = document.getElementById('api_key_pop').value
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/modify_acquirer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    acquirer: acquirer,
                    updates: temp
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_acquirer_detail(getViewpopup)
                    props.setLoading("success")
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {

        }
    }


    const modify_acquirer_status = async (updates, acquirer) => {
        try {
            props.setLoading("loading")

            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/modify_acquirer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    acquirer: acquirer,
                    updates: updates
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_acquirer()
                    props.setLoading("success")
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {

        }
    }

    const get_acquirer_detail = async (acquirer) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer_detail`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    acquirer: acquirer
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerDetail(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handlenamefilter = async (namehere) => {
        try {
            if (getLastname.length >= namehere.length) {
                get_acquirer()
            }
            if (namehere === "") {
                get_acquirer()
            } else {
                var temp = []
                const pattern = new RegExp(`${namehere}*`);;
                for (let i = 0; i < getAcquirerlist.length; i++) {
                    console.log(pattern.test(getAcquirerlist[i].name))
                    if (pattern.test(getAcquirerlist[i].name)) {
                        temp.push(getAcquirerlist[i])
                    }
                }
                setAcquirerlist([...temp])
            }
            setLastname(namehere)
        } catch (error) {
            console.log(error)
        }
    }

    const PopupAcq = () => {
        if (getViewpopup) {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setViewpopup("") }}>
                    </div>
                    <div className="childPopup">
                        <table>
                            <tr>
                                <td>
                                    <label>Name:</label>
                                </td>
                                <td>
                                    <input name="name" placeholder={getAcquirerDetail.name} disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Route:</label>
                                </td>
                                <td>
                                    <select name="route" disabled>
                                        <option>{getAcquirerDetail.route}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Rate:</label>
                                </td>
                                <td>
                                    <input id="rate_pop" placeholder={getAcquirerDetail.rate} />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>IMPS:</label>
                                </td>
                                <td>
                                    <input id="imps1_pop" placeholder={getAcquirerDetail.imps1} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label>IMPS:</label>
                                </td>
                                <td>
                                    <input id="imps2_pop" placeholder={getAcquirerDetail.imps2} />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <label>IMPS:</label>
                                </td>
                                <td>
                                    <input id="imps3_pop" placeholder={getAcquirerDetail.imps3} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td>
                                    <label>API KEY:</label>
                                </td>
                                <td>
                                    <input id="api_key_pop" placeholder={getAcquirerDetail.api_key} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Username:</label>
                                </td>
                                <td>
                                    <input id="username_pop" placeholder={getAcquirerDetail.username} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Password:</label>
                                </td>
                                <td>
                                    <input id="password_pop" placeholder={getAcquirerDetail.password} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>RTGS</label>
                                </td>
                                <td>
                                    <input id="rtgs_pop" placeholder={getAcquirerDetail.rtgs} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>NEFT:</label>
                                </td>
                                <td>
                                    <input id="neft_pop" placeholder={getAcquirerDetail.neft} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>UPI:</label>
                                </td>
                                <td>
                                    <input id="upi_pop" placeholder={getAcquirerDetail.upi} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <button name={getAcquirerDetail.id} onClick={(e) => { modify_acquirer(e.target.name) }}>Save</button>
                                </td>
                                <td>
                                </td>
                            </tr>
                        </table>
                    </div>
                </>
            )
        }
    }


    useEffect(() => {
        // props.check_cookie()

        get_route()
        get_acquirer()
        get_balance()

    }, [])

    const [isActive, setActive] = useState(true);
    const [defaulttext, setDefaulttext] = useState("View Options")

    function showOptions() {
        let height_transition_div = document.getElementById('height_transition_div');
        height_transition_div.classList.toggle('show_div')
        setActive(!isActive)
        setDefaulttext(!defaulttext)
    }





    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="right_dropdown_icon_container">
                        <button className='clearbtndisplay' >
                            <LuRefreshCw className='transferbtn' onClick={(e) => { get_acquirer() }} />
                        </button>
                        <button onClick={showOptions}>{defaulttext ? "View Options" : 'Close Options'} {isActive ? <IoIosArrowDropdown className='dropdown_icon' /> : <IoIosArrowDropup className='dropdown_icon' />}</button>
                    </div>

                    <div className='height_transition_div' id='height_transition_div'>
                        <div className="childTransaction">
                            {/* <PopupView /> */}
                            <table>
                                <tr>
                                    <td>
                                        <label>Name:</label>
                                    </td>
                                    <td>
                                        <input name="name" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }); handlenamefilter(e.target.value) }} placeholder="Enter Name" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Route:</label>
                                    </td>
                                    <td>
                                        <select name="route" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }); setsearchAcquirer({ ...getsearchAcquirer, [e.target.name]: e.target.value }) }}>
                                            <option value="">Select Acquirer</option>
                                            {
                                                getRoute.map((e, index) => {
                                                    return (
                                                        <option value={e.name}>{e.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>IMPS:</label>
                                    </td>
                                    <td>
                                        <input name="imps1" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value * 100 }) }} placeholder="Rs 0 - 1,000" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>IMPS:</label>
                                    </td>
                                    <td>
                                        <input name="imps2" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value * 100 }) }} placeholder="Rs. 1,001 - 25,000" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>IMPS:</label>
                                    </td>
                                    <td>
                                        <input name="imps3" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value * 100 }) }} placeholder="Rs. 25,001 - 5,00,000" />
                                    </td>
                                </tr>
                                {
                                    getAcquirer.route == "rbl" ?
                                        <tr>
                                            <td>
                                                <label>Acquirer IFSC:</label>
                                            </td>
                                            <td>
                                                <input name="api_salt" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Debit IFSC" />
                                            </td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    getAcquirer.route == "rbl" ?
                                        <tr>
                                            <td>
                                                <label>Certificate pem:</label>
                                            </td>
                                            <td>
                                                <input name="ssl_cert" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Certificate" />
                                            </td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    getAcquirer.route == "rbl" ?
                                        <tr>
                                            <td>
                                                <label>Certificate KEY pem:</label>
                                            </td>
                                            <td>
                                                <input name="ssl_key" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Certificate KEY" />
                                            </td>
                                        </tr>
                                        :
                                        <></>
                                }
                                {
                                    getAcquirer.route == "clickpay" ?
                                        <tr>
                                            <td>
                                                <label>Decription Key:</label>
                                            </td>
                                            <td>
                                                <input name="client_id" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Decription Key" />
                                            </td>
                                        </tr>
                                        : <></>
                                }

                            </table>
                            <table>
                                <tr>
                                    <td>
                                        {
                                            getAcquirer.route == "zyropay" ? <label>Wallet ID:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "razorpay" ? <label>API KEY:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "nexapay" ? <label>Client ID:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "rbl" ? <label>Account Number:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "clickpay" ? <label>MID:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "axis_cms" ? <label>Debit Account Number:</label> : <></>
                                        }
                                    </td>
                                    <td>
                                        <input name="api_key" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter API KEY" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            getAcquirer.route == "zyropay" ? <label>Email:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "razorpay" ? <label>Username:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "nexapay" ? <label>Client ID:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "rbl" ? <label>Username:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "clickpay" ? <label>Encryption Key:</label> : <></>
                                        }
                                    </td>
                                    <td>
                                        <input name="username" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter Username" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {
                                            getAcquirer.route == "zyropay" ? <label>Password:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "razorpay" ? <label>Password:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "nexapay" ? <label>Salt:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "rbl" ? <label>Password:</label> : <></>
                                        }
                                        {
                                            getAcquirer.route == "clickpay" ? <label>Encryption Salt:</label> : <></>
                                        }
                                    </td>
                                    <td>
                                        <input name="password" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter Password" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>RTGS</label>
                                    </td>
                                    <td>
                                        <input name="rtgs" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value * 100 }) }} placeholder="RTGS charges" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>NEFT:</label>
                                    </td>
                                    <td>
                                        <input name="neft" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value * 100 }) }} placeholder="NEFT charges" />
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <label>UPI:</label>
                                    </td>
                                    <td>
                                        <input name="upi" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value * 100 }) }} placeholder="UPI charges" />
                                    </td>
                                </tr>
                                {
                                    getAcquirer.route == "rbl" ?
                                        <tr>
                                            <td>
                                                <label>Client ID:</label>
                                            </td>
                                            <td>
                                                <input name="client_id" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Client ID" />
                                            </td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    getAcquirer.route == "rbl" ?
                                        <tr>
                                            <td>
                                                <label>Client Secret:</label>
                                            </td>
                                            <td>
                                                <input name="client_secret" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Client Secret" />
                                            </td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    getAcquirer.route == "clickpay" ?
                                        <tr>
                                            <td>
                                                <label>Decription Salt:</label>
                                            </td>
                                            <td>
                                                <input name="client_secret" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Decription Salt" />
                                            </td>
                                        </tr>
                                        : <></>
                                }
                            </table>
                        </div>
                        <div className="buttonset">
                            <button onClick={(e) => { create_acquirer() }}>Create Acquirer</button>
                            <button onClick={(e) => { get_acquirer() }}>Search Acquirer</button>
                        </div>
                    </div>

                </div>
                <div className="rowscount">
                    <select onBlur={(e) => { setRow(e.target.value); }}>
                        <option value={10}>10 Rows</option>
                        <option value={20}>20 Rows</option>
                        <option value={50}>50 Rows</option>
                        <option value={100}>100 Rows</option>
                    </select>
                </div>
                <div className="tran_table">

                    <div>
                        <table>
                            <tr>
                                <th>Acquirer Name</th>
                                <th>Route</th>
                                <th>Balance</th>
                                <th>Status</th>
                                <th>Reconcile</th>
                                <th>Workng</th>
                                <th>View</th>
                            </tr>
                            {
                                getAcquirerlist.map((e, index) => {
                                    var customclass = "";
                                    if (index % 2 === 0) {
                                        customclass = "darkishere"
                                    }
                                    return (
                                        <tr className={`minimum_width_ex ${customclass}`}>
                                            <td>{e.name}</td>
                                            <td>{e.route}</td>
                                            <td>₹{parseFloat(e.balance).toLocaleString("en-IN")}</td>
                                            <td className='inputonoff'>
                                                {
                                                    e.status ?
                                                        <input name={e.id} type="checkbox" checked={e.status} onClick={(e) => { modify_acquirer_status({ status: false }, e.target.name) }}></input>
                                                        :
                                                        <input name={e.id} type="checkbox" checked={e.status} onClick={(e) => { modify_acquirer_status({ status: true }, e.target.name) }}></input>

                                                }
                                            </td>
                                            <td className='inputonoff'>
                                                {
                                                    e.reconcile ?
                                                        <input name={e.id} type="checkbox" checked={e.reconcile} onClick={(e) => { modify_acquirer_status({ reconcile: false }, e.target.name) }}></input>
                                                        :
                                                        <input name={e.id} type="checkbox" checked={e.reconcile} onClick={(e) => { modify_acquirer_status({ reconcile: true }, e.target.name) }}></input>

                                                }
                                            </td>
                                            <td className='inputonoff'>
                                                {
                                                    e.working ?
                                                        <input name={e.id} type="checkbox" checked={e.working} onClick={(e) => { modify_acquirer_status({ working: false }, e.target.name) }}></input>
                                                        :
                                                        <input name={e.id} type="checkbox" checked={e.working} onClick={(e) => { modify_acquirer_status({ working: true }, e.target.name) }}></input>

                                                }
                                            </td>
                                            <td>
                                                <button className='clearbtndisplay' >
                                                    <FaEye className='transferbtn' onClick={(f) => { setViewpopup(e.id); get_acquirer_detail(e.id) }} />
                                                </button>

                                            </td>

                                        </tr>
                                    )
                                })
                            }
                        </table>
                        <div style={{ display: "none" }} id='loadinganimation' class="loader-line"></div>
                    </div>
                </div>
                <div className="childAccount1">
                    < PopupAcq />
                </div>
            </div>

        </>
    )
}


export default Merchant