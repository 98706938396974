import React, { useState } from "react";
import Cookies from 'universal-cookie'
import { useNavigate } from "react-router-dom";
import '../css/Login.css'


const Merchantlogin = () => {
    const [getLogin, setLogin] = useState({})
    const navigate = useNavigate()
    const loginClick = async () => {
        try {
            var button = document.querySelector('.button');
            var spinner = '<span class="spinner"></span>';
            button.classList.add('loading');
            button.innerHTML = spinner;
            const res = await fetch(`${process.env.REACT_APP_URL}/merchant/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify(getLogin)
            })
            const dataset = await res.json()
            console.log(dataset)
            if (res.status === 200) {
                if (dataset.status === true) {
                    const boxes = document.querySelectorAll('.box');
                    for (const box of boxes) {
                        console.log(box)
                        box.classList.add('animationfade');
                    }
                    const cookies = new Cookies()
                    cookies.set('merchant', dataset.data.token, { path: '/' })
                    
                    
                    const transferpage = () => {navigate('/merchant/dashboard') }
                    setTimeout(transferpage, 300)
                }else {
                    var button = document.querySelector('.button');
                    button.classList.remove('loading');
                    button.innerHTML = "Login";
                    alert(dataset.err.msg)
                }
            } else {
                var button = document.querySelector('.button');
                button.classList.remove('loading');
                button.innerHTML = "Login";
                alert("network error")
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div class="parent_container">
                <div class="container">
                    <div className="top  box"></div>
                    <div className="bottom box"></div>
                    <div className="center">
                        <h1>Login</h1>

                        <label>Email</label>
                        <input name="email" onChange={(e) => { setLogin((values) => ({ ...values, [e.target.name]: e.target.value })) }} />
                        <label>Password</label>
                        <input type="password" name="password" onChange={(e) => { setLogin((values) => ({ ...values, [e.target.name]: e.target.value })) }} />
                        <button className="button" onClick={(e) => { loginClick() }} ><div class="loader"></div>Login</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Merchantlogin;