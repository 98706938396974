import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css';
import { CopyBlock } from 'react-code-blocks';


const Merchantdeveloper = (props) => {

    const [getAccount, setAccount] = useState({})
    const [getLanguage, setLanguage] = useState(0)
    const [getAmount, setAmount] = useState("")
    const [getAccountn, setAccountn] = useState("")
    const [getIfsc, setIfsc] = useState("")
    const [getMode, setMode] = useState("")
    const [getOrder, setOrder] = useState("")
    const [getName, setName] = useState("")
    const cookie = new Cookies()

    const get_account = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_account`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (dataset.status === true) {
                setAccount(dataset.data.data)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const import_code = [
        {
            name: "javascript",
            code: `const crypto = require('crypto');



            const hash1 = async (amount, account_number, mode, order_id, salt) => {
                const Stringtohash = \`\${amount}\` + account_number + mode + order_id + salt
                const sha256Hash = await crypto.createHash('sha256');
                await sha256Hash.update(Stringtohash);
                const hashedData = await sha256Hash.digest('hex');
                return hashedData
            }
            
            
            const encrypt = async (enc_key, data) => {
                try {
                    const key = await crypto.createHash('sha512').update(enc_key).digest('hex').substring(0, 32)
                    const encryptionIV = await crypto.createHash('sha512').update(enc_key).digest('hex').substring(0, 16)
                    const cipher = await crypto.createCipheriv("aes-256-cbc", key, encryptionIV)
                    const enc_dataset = await Buffer.from(
                        cipher.update(data, 'utf8', 'hex') + cipher.final('hex')
                    ).toString('base64')
                    return enc_dataset
                } catch (error) {
                    console.log(error)
                }
            }
            
            const decrypt = async (dec_key, data) => {
                try {
                    const key = await crypto.createHash('sha512').update(dec_key).digest('hex').substring(0, 32)
                    const encryptionIV = await crypto.createHash('sha512').update(dec_key).digest('hex').substring(0, 16)
                    const buff = await Buffer.from(data, 'base64')
                    const decipher = await crypto.createDecipheriv("aes-256-cbc", key, encryptionIV)
                    const dec_dataset = await decipher.update(buff.toString('utf8'), 'hex', 'utf8') +
                        decipher.final('utf8')
                    return dec_dataset
                } catch (error) {
                    console.log(error)
                }
            }
            
            
            const hellotest = async () => {
                const enc_key = "keysgoeshere"
                const salt = "Salthere"
                const d = new Date()
                const amount = "${getAmount}"
                const account_number = "${getAccountn}"
                const mode = "${getMode}"
                const order_id = "${getOrder}"
                const ifsc_code = "${getIfsc}"
                const name = "${getName}"
                const temp = {
                    amount: amount,
                    account_number: account_number,
                    mode: mode,
                    order_id: order_id,
                    ifsc_code: ifsc_code,
                    name: name,
                    signature: await hash1(amount, account_number, mode, order_id, salt)
                }
                console.log(temp.signature)
                console.log(await encrypt(enc_key,JSON.stringify(temp)))
            }
            
            hellotest()
            `
        },
        {
            name: "php",
            code: `<?php

            function encrypt($enc_key, $data) {
                try {
                    $key = substr(hash('sha512', $enc_key), 0, 32);
                    $encryptionIV = substr(hash('sha512', $enc_key), 0, 16);
                    $cipher = openssl_encrypt($data, 'aes-256-cbc', $key, OPENSSL_RAW_DATA, $encryptionIV);
                    $hexCipher = bin2hex($cipher);
                    $enc_dataset = base64_encode($hexCipher);
                    return $enc_dataset;
                } catch (Exception $error) {
                    echo $error;
                }
            }
            
            
            function decrypt($enc_key, $encryptedData) {
                try {
                    $key = substr(hash('sha512', $enc_key), 0, 32);
                    $encryptionIV = substr(hash('sha512', $enc_key), 0, 16);
                    $hexCipher = base64_decode($encryptedData);
                    $cipher = hex2bin($hexCipher);
                    $decryptedData = openssl_decrypt($cipher, 'aes-256-cbc', $key, OPENSSL_RAW_DATA, $encryptionIV);
                    return $decryptedData;
                } catch (Exception $error) {
                    echo $error;
                }
            }
            
            
            function sha256Hash($amount, $account_number, $mode, $order_id, $salt) {
                $data = $amount. $account_number. $mode. $order_id. $salt;
                $hash = hash('sha256', $data);
                return $hash;
            }
            
            
            $enc_key = "yourkeysgoeshere";
            $salt = "Salthere";
            $amount = "${getAmount}";
            $account_number = "${getAccountn}";
            $mode = "${getMode}";
            $order_id = "${getOrder}";
            $ifsc_code = "${getIfsc}";
            $name = "${getName}";
            $data = array(
                'amount' => $amount,
                'account_number' => $account_number,
                'mode' => $mode,
                'order_id' => $order_id,
                'ifsc_code' => $ifsc_code,
                'name' => $name,
                'signature' => sha256Hash($amount, $account_number, $mode, $order_id, $salt)
            );
            $jsonString = json_encode($data);
            $result = encrypt($enc_key,  $jsonString);
            echo "Encrypted Data: " . $result;
            $result1 = decrypt($enc_key,  $result);
            echo "Dencrypted Data: " . $result1;
            ?>`
        },
        {

        },
        {
            name:"csharp",
            code: `using System;
            using System.Security.Cryptography;
            using System.Text;
            
            class Program
            {
            
                public static string Base64Encode(string plainText)
                {
                    var plainTextBytes = System.Text.Encoding.UTF8.GetBytes(plainText);
                    return System.Convert.ToBase64String(plainTextBytes);
                }
                static string ComputeSHA512Hash(string input)
                {
                    using (SHA512 sha512 = SHA512.Create())
                    {
                        byte[] hashBytes = sha512.ComputeHash(Encoding.UTF8.GetBytes(input));
                        string hashString = BitConverter.ToString(hashBytes).Replace("-", "").ToLower();
                        return hashString;
                    }
                }
            
                public static string Base64Decode(string base64Encoded)
                {
                    try
                    {
                        byte[] base64Bytes = Convert.FromBase64String(base64Encoded);
                        string plainText = Encoding.UTF8.GetString(base64Bytes);
                        return plainText;
                    }
                    catch (FormatException)
                    {
                        throw new ArgumentException("Input is not a valid base64-encoded string.");
                    }
                }
            
            
                static string encrypt(string data, string encryptionKey)
                {
                    byte[] keyBytes = Encoding.UTF8.GetBytes(ComputeSHA512Hash(encryptionKey));
                    Array.Resize(ref keyBytes, 32);
                    byte[] ivBytes = Encoding.UTF8.GetBytes(ComputeSHA512Hash(encryptionKey));
                    Array.Resize(ref ivBytes, 16);
                    using (Aes aesAlg = Aes.Create())
                    {
                        aesAlg.Key = keyBytes;
                        aesAlg.IV = ivBytes;
                        aesAlg.Mode = CipherMode.CBC;
                        ICryptoTransform encryptor = aesAlg.CreateEncryptor(aesAlg.Key, aesAlg.IV);
                        byte[] inputBytes = Encoding.UTF8.GetBytes(data);
                        using (var msEncrypt = new System.IO.MemoryStream())
                        using (var csEncrypt = new CryptoStream(msEncrypt, encryptor, CryptoStreamMode.Write))
                        {
                            csEncrypt.Write(inputBytes, 0, inputBytes.Length);
                            csEncrypt.FlushFinalBlock();
                            byte[] encryptedBytes = msEncrypt.ToArray();
                            string hexCipherText = BitConverter.ToString(encryptedBytes).Replace("-", string.Empty);
                            string base64wel = Base64Encode(hexCipherText.ToLower());
                            return base64wel;
                        }
                    }
                }
            
            
                static string decrypt(string hexCipherText, string encryptionKey)
                {
                    byte[] keyBytes = Encoding.UTF8.GetBytes(ComputeSHA512Hash(encryptionKey));
                    Array.Resize(ref keyBytes, 32);
                    byte[] ivBytes = Encoding.UTF8.GetBytes(ComputeSHA512Hash(encryptionKey));
                    Array.Resize(ref ivBytes, 16);
                    using (Aes aesAlg = Aes.Create())
                    {
                        aesAlg.Key = keyBytes;
                        aesAlg.IV = ivBytes;
                        aesAlg.Mode = CipherMode.CBC;
                        ICryptoTransform decryptor = aesAlg.CreateDecryptor(aesAlg.Key, aesAlg.IV);
                        byte[] encryptedBytes = HexStringToBytes(hexCipherText);
                        using (var msDecrypt = new System.IO.MemoryStream(encryptedBytes))
                        using (var csDecrypt = new CryptoStream(msDecrypt, decryptor, CryptoStreamMode.Read))
                        using (var srDecrypt = new System.IO.StreamReader(csDecrypt))
                        {
                            string decryptedText = srDecrypt.ReadToEnd();
                            return decryptedText;
                        }
                    }
                }
            
                static byte[] HexStringToBytes(string hex)
                {
                    int numberChars = hex.Length;
                    byte[] bytes = new byte[numberChars / 2];
                    for (int i = 0; i < numberChars; i += 2)
                    {
                        bytes[i / 2] = Convert.ToByte(hex.Substring(i, 2), 16);
                    }
                    return bytes;
                }
                static string CalculateSHA256(string input)
                {
                    using (SHA256 sha256 = SHA256.Create())
                    {
                        byte[] bytes = Encoding.UTF8.GetBytes(input);
                        byte[] hashBytes = sha256.ComputeHash(bytes);
                        StringBuilder stringBuilder = new StringBuilder();
                        for (int i = 0; i < hashBytes.Length; i++)
                        {
                            stringBuilder.Append(hashBytes[i].ToString("x2"));
                        }
                        return stringBuilder.ToString();
                    }
                }
            
            
                static void Main()
                {
                    string encryptionKey = "cd869711b2f18e20556c8f4264416a043b5633756b5aeb012aaae6bb9734dd0e"; // Replace with your own 256-bit key
            
                    string amount = "${getAmount}"
                    string currency = "INR";
                    string email = "sameerdev1203@gmail.com";
                    string orderid = "TEST12234411";
                    string phone = "9989111248";
                    string salt = "3e67a9811e5d37a21556";
            
                    string concatenatedString = amount + currency + email + orderid + phone + salt;
            
                    string sha256Hash = CalculateSHA256(concatenatedString);
                    Console.WriteLine("SHA-256 Hash: " + sha256Hash);
            
                    string data = "{\\"amount\\":1200,\\"currency\\":\\"INR\\",\\"email\\":\\"wel@gmail.com\\",\\"phone\\":9999999999,\\"order_id\\":\\"wele1699333568422\\","+"\\"signature\\":\\"4e282ce6c085c7651b0ca9adf6c3c5d747e8153ba62be884d9865960d627ec45\\"}";
            
                    string encryptedata = encrypt(data, encryptionKey);
                    // Console.WriteLine(encryptedata);
            
            
                    string decoded = Base64Decode(encryptedata);
                    string decrypteddata = decrypt(decoded, encryptionKey);
                    // Console.WriteLine(decrypteddata);
            
                }
            
            
            }`
        }
    ]




    useEffect(() => {
        // props.check_cookie()
        get_account()
    }, [""])


    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="childTransaction">

                        <table>
                            <tr>
                                <td>
                                    <label>Amount:</label>
                                </td>
                                <td>
                                    <input id="amount" name="amount" onChange={(e) => { }} placeholder="Enter Account Number" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Account Number:</label>
                                </td>
                                <td>
                                    <input id="account" name="account_number" onChange={(e) => { }} placeholder="Enter Account Number" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>IFSC:</label>
                                </td>
                                <td>
                                    <input id="ifsc" name="ifsc_code" onChange={(e) => { }} placeholder="Enter IFSC Code" />
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td>
                                    <label>Mode:<text style={{ color: "red" }}>*</text></label>
                                </td>
                                <td>
                                    <select id="mode" >
                                        <option>Select Mode</option>
                                        <option value="IMPS">IMPS</option>
                                        <option value="RTGS">RTGS</option>
                                        <option value="NEFT">NEFT</option>
                                        <option value="UPI">UPI</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Name:</label>
                                </td>
                                <td>
                                    <input id="name" name="name" onChange={(e) => { }} placeholder="Enter Customer Names" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Order Id:</label>
                                </td>
                                <td>
                                    <input id="name" name="name" onChange={(e) => { }} placeholder="Enter Customer Names" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="buttonset">
                        <select onChange={(e)=>{setLanguage(e.target.value)}}>
                            <option value="0">Javascript</option>
                            <option value="1">PHP</option>
                            {/* <option value="2">JAVA</option> */}
                            <option value="3">.NET C#</option>
                        </select>
                    </div>
                    <div className="childTransactioncodeblock" style={{ padding: "10px", justifyContent: "center" }}>
                        <CopyBlock
                            text={import_code[getLanguage].code}
                            language={import_code[getLanguage].name}
                            showLineNumbers={true}
                            wrapLongLines={true}
                        />
                    </div>
                </div>
            </div>
            {/* <style >
                {
                    `
                    .parentTransaction{
                        color: black;
                        margin-left: 265px;
                        border-radius: 30px 0px 0px 30px;
                        // height: 100vh;
                    }
                    .childTransaction{
                        display:flex;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .childTransaction table,tr{
                        margin: 10px;
                        width: 100%;
                    }
                    label{
                        background: #ffffff;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 10px;
                        color: black;
                    }
                    td{
                        background: #ffffff;
                        padding: 20px;
                    }
                    table, th, td,tr{
                        border-spacing: 0;
                        border-collapse: collapse;
                        background: #ffffff;
                    }
                    select, input{
                        color: #6d7782;
                        padding: 5px;
                        border-radius: 10px;
                    }
                    .buttonset{
                        text-align: center;
                        padding: 10px;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .buttonset button{
                        margin: 10px;
                        background-color: #edefef;
                        text-align: center;
                        color: #d85539;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button{
                        background-color: #edefef;
                        text-align: center;
                        color: #6a7580;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .buttonset button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .blockside{
                        text-align: left;
                        background: #ffffff;
                        margin: 10px;
                        border-radius: 10px;
                        display: block;
                        // border: 1px solid #6a7580;
                    }
                    input{
                        color-scheme: light;
                    }
                    select, input{
                        background-color: white;
                        padding: 10px;
                        border: 1px solid #6a7580;
                    }
                    .tran_table div, table, tr, th,td{
                        padding: 3px;
                        background-color: #ffffff;
                    }
                    .tran_table div{
                        border-radius: 10px;
                    }
                    .tran_table{
                        overflow-y: scroll;
                        height: 50vh;
                        padding: 10px;
                        background-color: #ffffff;
                        text-align: center;
                        border-radius: 10px;
                        margin: 15px;
                        margin-top: 20px;
                    }
                    .tran_table::-webkit-scrollbar{
                        display: none;
                    }
                    .tran_table table{
                        width: 100%;   
                    }
                    .tran_table td,th{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    .tran_table th{
                        background-color: #d85539;
                        color: white;
                    }
                    .tran_table select{
                        padding: 5px;
                    }

                    .date{
                        margin-left: 40%;
                    }
                    .rowscount{
                        text-align: right;
                    }


                    .parentPopup {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.8);
                        color: white;
                        z-index: 1;
                    }
                    .childPopup{
                        color: white;
                        z-index: 0;
                        position: fixed; 
                        top: 50%; left:50%;
                        width: 70%;
                        text-align: center;
                        transform:translate(-50%,-50%);
                        border-radius: 10px;
                        display: flex;
                        text-align: left;
                        flex-shrink: 0;
                        // flex-wrap: wrap;
                        align-items: center;
                        margin: 10px;
                        padding: 10px;
                        background: #ffffff;
                        z-index: 2;
                    }
                    .childPopup table{
                        width: 100%;
                    }
                    .childPopup div, label{
                        background: #ffffff;
                    }
                    .close{
                        display: fixed;
                    }
                    .childPopup select{
                        color: black;
                        border-radius: 10px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }
                    `
                }
            </style> */}
        </>
    )
}


export default Merchantdeveloper