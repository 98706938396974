import Cookies from 'universal-cookie'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";





const Merhcantlogout = (props) => {

    const cookies = new Cookies()
    const navigate = useNavigate()


    const logout = async () => {
        try {
            cookies.remove("merchant");
            navigate('/merchant')
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        logout()
    }, [])

    return (
        <>
        </>
    )
}


export default Merhcantlogout