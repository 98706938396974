import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css';


const Merchantaccount = (props) => {

    const [getAccount, setAccount] = useState({})
    const [getPopup, setPopup] = useState(false)
    const [getPopupwebhook, setPopupwebhook] = useState(false)
    const cookie = new Cookies()

    const get_account = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_account`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (dataset.status === true) {
                setAccount(dataset.data.data)
            }

        } catch (error) {
            console.log(error)
        }
    }


    const change_password = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/change_password`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    password: document.getElementById("password").value
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
        } catch (error) {
            console.log(error)
        }
    }
    const change_webhook = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/change_webhook`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    webhook: document.getElementById("webhook").value
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await resp.json()
            if (resp.status == 200) {
                if (dataset.status == true) {
                    get_account()
                    setPopupwebhook(false)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        // props.check_cookie()
        get_account()
    }, [""])


    const PopupAcq = () => {
        if (getPopup) {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setPopup(false) }}>
                    </div>
                    <div className="childPopup">
                        <div>
                            <label>New Password:</label>
                        </div>
                        <div>
                            <input id="password"></input>
                        </div>
                        <div>
                            <button onClick={(e) => { change_password() }}>Change password</button>
                        </div>
                    </div>
                </>
            )
        }
    }

    const PopupWebhook = () => {
        if (getPopupwebhook) {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setPopupwebhook(false) }}>
                    </div>
                    <div className="childPopup">
                        <div>
                            <label>New Webhook:</label>
                        </div>
                        <div>
                            <input id="webhook"></input>
                        </div>
                        <div>
                            <button onClick={(e) => { change_webhook() }}>Change Webhook</button>
                        </div>
                    </div>
                </>
            )
        }
    }
    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="childTransaction">
                        <div className="tabletie">
                            <tr>
                                <th>Name:</th>
                                <td>{getAccount.name}</td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>{getAccount.email}</td>
                            </tr>
                            <tr>
                                <th>Mobile:</th>
                                <td>{getAccount.phone}</td>
                            </tr>
                            <tr>
                                <th>MID:</th>
                                <td>{getAccount.mid}</td>
                            </tr>
                            <tr>
                                <th>Encryption Key:</th>
                                <td>{getAccount.enc_key}</td>
                            </tr>
                            <tr>
                                <th>Encryption Salt:</th>
                                <td>{getAccount.enc_salt}</td>
                            </tr>
                            <tr>
                                <th>Dencryption Key:</th>
                                <td>{getAccount.dec_key}</td>
                            </tr>
                            <tr>
                                <th>Dencryption Salt:</th>
                                <td>{getAccount.dec_salt}</td>
                            </tr>
                            <tr>
                                <th>Webhook:</th>
                                <td>{getAccount.webhook}</td>
                            </tr>
                        </div>
                    </div>
                    <div className="buttonset">
                        <button onClick={(e) => { setPopup(true) }}>Change Password</button>
                        <button onClick={(e) => { setPopupwebhook(true) }}>Change Webhook</button>
                    </div>
                    < PopupAcq />
                    <PopupWebhook />
                </div>
            </div>
            {/* <style >
                {
                    `
                    .parentTransaction{
                        color: black;
                        margin-left: 265px;
                        border-radius: 30px 0px 0px 30px;
                        // height: 100vh;
                    }
                    .childTransaction{
                        display:flex;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .childTransaction table,tr{
                        margin: 10px;
                        width: 100%;
                    }
                    label{
                        background: #ffffff;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 10px;
                        color: black;
                    }
                    td{
                        background: #ffffff;
                        padding: 20px;
                    }
                    table, th, td,tr{
                        border-spacing: 0;
                        border-collapse: collapse;
                        background: #ffffff;
                    }
                    select, input{
                        color: #6d7782;
                        padding: 5px;
                        border-radius: 10px;
                    }
                    .buttonset{
                        text-align: center;
                        padding: 10px;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .buttonset button{
                        margin: 10px;
                        background-color: #edefef;
                        text-align: center;
                        color: #d85539;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button{
                        background-color: #edefef;
                        text-align: center;
                        color: #6a7580;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .buttonset button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .blockside{
                        text-align: left;
                        background: #ffffff;
                        margin: 10px;
                        border-radius: 10px;
                        display: block;
                        // border: 1px solid #6a7580;
                    }
                    input{
                        color-scheme: light;
                    }
                    select, input{
                        background-color: white;
                        padding: 10px;
                        border: 1px solid #6a7580;
                    }
                    .tran_table div, table, tr, th,td{
                        padding: 3px;
                        background-color: #ffffff;
                    }
                    .tran_table div{
                        border-radius: 10px;
                    }
                    .tran_table{
                        overflow-y: scroll;
                        height: 50vh;
                        padding: 10px;
                        background-color: #ffffff;
                        text-align: center;
                        border-radius: 10px;
                        margin: 15px;
                        margin-top: 20px;
                    }
                    .tran_table::-webkit-scrollbar{
                        display: none;
                    }
                    .tran_table table{
                        width: 100%;   
                    }
                    .tran_table td,th{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    .tran_table th{
                        background-color: #d85539;
                        color: white;
                    }
                    .tran_table select{
                        padding: 5px;
                    }

                    .date{
                        margin-left: 40%;
                    }
                    .rowscount{
                        text-align: right;
                    }


                    .parentPopup {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.8);
                        color: white;
                        z-index: 1;
                    }
                    .childPopup{
                        color: white;
                        z-index: 0;
                        position: fixed; 
                        top: 50%; left:50%;
                        width: 70%;
                        text-align: center;
                        transform:translate(-50%,-50%);
                        border-radius: 10px;
                        display: flex;
                        text-align: left;
                        flex-shrink: 0;
                        // flex-wrap: wrap;
                        align-items: center;
                        margin: 10px;
                        padding: 10px;
                        background: #ffffff;
                        z-index: 2;
                    }
                    .childPopup table{
                        width: 100%;
                    }
                    .childPopup div, label{
                        background: #ffffff;
                    }
                    .close{
                        display: fixed;
                    }
                    .childPopup select{
                        color: black;
                        border-radius: 10px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }
                    `
                }
            </style> */}
        </>
    )
}


export default Merchantaccount