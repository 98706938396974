import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { LuRefreshCw } from "react-icons/lu";



const Merchanttransaction = (props) => {
    const [getFilter, setFilter] = useState({})
    const [getRow, setRow] = useState(50)
    const [getTransaction, setTransaction] = useState([{}])

    const [getTransactionDetail, setTransactionDetail] = useState("")
    const [getTransactionDetails, setTransactionDetails] = useState({})
    const cookie = new Cookies()




    const get_transaction = async () => {
        try {
            setTransaction([{}])
            document.getElementById("loadinganimation").style.display = 'block'

            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_transaction`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    count: getRow,
                    filter: ffilter
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransaction(dataset.data.data)
                    document.getElementById("loadinganimation").style.display = 'none'
                } else {
                    document.getElementById("loadinganimation").style.display = 'none'
                }
            } else {
                document.getElementById("loadinganimation").style.display = 'none'
            }
        } catch (error) {
            console.log(error)
        }
    }

    const check_status_update = async (order_id) => {
        try {
            var button = document.querySelector('#checkstatusbtn');
            var spinner = '<span class="spinner" style="margin-left: 65px"></span>';
            button.classList.add('loading');
            button.innerHTML = spinner;
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/check_status_update`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    order_id: order_id
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionDetails(dataset.data.data[0])
                    get_transaction()
                    var button = document.querySelector('#checkstatusbtn');
                    button.classList.remove('loading');
                    button.innerHTML = "Check Status";
                } else {
                    var button = document.querySelector('#checkstatusbtn');
                    button.classList.remove('loading');
                    button.innerHTML = "Check Status";
                }
            } else {
                var button = document.querySelector('#checkstatusbtn');
                button.classList.remove('loading');
                button.innerHTML = "Check Status";
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_transaction_details = async (order_id) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_transaction_details`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    order_id: order_id
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionDetails(dataset.data.data[0])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const PopupAcq = () => {
        if (getTransactionDetail !== "") {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setTransactionDetail("") }}>
                    </div>
                    <div data-aos="fade-left" className="transactionPopup">
                        <div className='popup_header'>
                            <label>Test Merchant</label>
                        </div>
                        <div className='popup_body'>
                            <label>Beneficiary Details</label>
                            <div className='item'>
                                <table className='fieldtable'>
                                    <tr>
                                        <td>Name</td>
                                    </tr>
                                    <tr>
                                        <td>Account</td>
                                    </tr>
                                    <tr>
                                        <td>IFSC</td>
                                    </tr>
                                </table>
                                <table className='chikichiki'>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                </table>
                                <table className='valuetable'>
                                    <tr>
                                        <td>{getTransactionDetails.name}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.account}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.ifsc}</td>
                                    </tr>
                                </table>
                            </div>
                            <label>Transaction Details</label>
                            <div className='item'>
                                <table className='fieldtable'>
                                    <tr>
                                        <td>Mode</td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                    </tr>
                                </table>
                                <table className='chikichiki'>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                </table>
                                <table className='valuetable'>
                                    <tr>
                                        <td>{getTransactionDetails.mode}</td>
                                    </tr>
                                    <tr>
                                        <td>₹{(getTransactionDetails.amount / 100).toLocaleString("en-IN")}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.status}</td>
                                    </tr>

                                </table>
                            </div>
                            <label>Order Details</label>
                            <div className='item'>
                                <table className='fieldtable'>
                                    <tr>
                                        <td>Order ID</td>
                                    </tr>
                                    <tr>
                                        <td>Txn ID</td>
                                    </tr>
                                    <tr>
                                        <td>Ref ID</td>
                                    </tr>
                                    <tr>
                                        <td>RRN</td>
                                    </tr>
                                    <tr>
                                        <td>Error</td>
                                    </tr>
                                </table>
                                <table className='chikichiki'>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                </table>
                                <table className='valuetable'>
                                    <tr>
                                        <td>{getTransactionDetails.order_id}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.txn_id}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.ref_id}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.rrn}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: '10px' }}>{getTransactionDetails.error}</td>
                                    </tr>
                                </table>
                            </div>
                            {(getTransactionDetails.service === "Admin Debit" || getTransactionDetails.service === "Admin Credit" || getTransactionDetails.status !== "PENDING") ? <></> :
                                <div className='widebtn'>
                                    <button className='checkbtn' id='checkstatusbtn' onClick={(f) => { check_status_update(getTransactionDetails.order_id) }}>Check Status</button>
                                </div>
                            }
                        </div>
                    </div>
                </>
            )
        }
    }

    useEffect(() => {
        // props.check_cookie()
        get_transaction()
    }, [])


    const [isActive, setActive] = useState(true);
    const [defaulttext, setDefaulttext] = useState("View Options")

    function showOptions() {
        setFilter({})
        let height_transition_div = document.getElementById('height_transition_div');
        height_transition_div.classList.toggle('show_div')
        let show_div = document.getElementById('height_transition_div_here');
        show_div.classList.toggle('show_div_here')
        setActive(!isActive)
        setDefaulttext(!defaulttext)
    }
    return (
        <>
            < PopupAcq />
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="right_dropdown_icon_container">
                        <button className='clearbtndisplay' >
                            <LuRefreshCw className='transferbtn' onClick={(e) => { get_transaction() }} />
                        </button>
                        <button onClick={showOptions}>{defaulttext ? "View Options" : 'Close Options'} {isActive ? <IoIosArrowDropdown className='dropdown_icon' /> : <IoIosArrowDropup className='dropdown_icon' />}</button>
                    </div>
                    <div id='height_transition_div_here' className='height_transition_div_here'>
                        <td>
                            <label>Transaction ID:</label>
                        </td>
                        <td>
                            <input name="txn_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Transaction ID" />
                        </td>
                        <td>
                            <label>Order ID:</label>
                        </td>
                        <td>
                            <input name="order_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Order ID" />
                        </td>
                        <td>
                            <label>RRN:</label>
                        </td>
                        <td>
                            <input name="rrn" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter RRN/UTR" />
                        </td>
                        <td>
                            <button onClick={(e) => { get_transaction(); }}>Search</button>
                        </td>
                    </div>
                    <div className='height_transition_div' id='height_transition_div'>
                        <div className="childTransaction">
                            {/* <PopupView /> */}
                            <table>
                                <tr>
                                    <td>
                                        <label>Status:</label>
                                    </td>
                                    <td>
                                        <select name="status" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                            <option>Status Type</option>
                                            <option value="SUCCESS">SUCCESS</option>
                                            <option value="PENDING">PENDING</option>
                                            <option value="FAILED">FAILED</option>
                                            <option value="QUEUED">QUEUED</option>
                                            <option value="INITIATED">INITIATED</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Amount:</label>
                                    </td>
                                    <td>
                                        <input name="amount" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value * 100 } }) }} placeholder="Enter Amount" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Account Number:</label>
                                    </td>
                                    <td>
                                        <input name="bene_account" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Account" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>IFSC:</label>
                                    </td>
                                    <td>
                                        <input name="bene_ifsc" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter IFSC Code" />
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td>
                                        <label>rrn:</label>
                                    </td>
                                    <td>
                                        <input name="rrn" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter RRN/UTR" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Transaction ID:</label>
                                    </td>
                                    <td>
                                        <input name="txn_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Transaction ID" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Order ID:</label>
                                    </td>
                                    <td>
                                        <input name="order_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Order ID" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Reference ID:</label>
                                    </td>
                                    <td>
                                        <input name="ref_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Reference ID" />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="buttonset">
                            <button onClick={(e) => { get_transaction(); }}>Search Merchant</button>
                        </div>
                    </div>

                </div>
                <div className="rowscount">
                    <select onBlur={(e) => { get_transaction(); }} onChange={(e) => { setRow(e.target.value); }}>
                        <option value={50}>50 Rows</option>
                        <option value={100}>100 Rows</option>
                        <option value={500}>500 Rows</option>
                        <option value={1000}>1000 Rows</option>
                        <option value={5000}>5000 Rows</option>
                    </select>
                </div>
                <div className="tran_table">
                    <div>
                        <table>
                            <tr>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>Service</th>
                                <th>Order ID</th>
                                <th>RRN/UTR</th>
                                <th>Date & Time</th>
                            </tr>
                            {
                                getTransaction.map((e, index) => {
                                    const updatetimeorg = new Date(e.updated_time)
                                    const createtimeorg = new Date(e.created_time)
                                    const updatetime = `${(updatetimeorg.getDate() > 9) ? updatetimeorg.getDate() : '0' + updatetimeorg.getDate()}/${(updatetimeorg.getMonth() > 8) ? updatetimeorg.getMonth() + 1 : '0' + (updatetimeorg.getMonth() + 1)}/${updatetimeorg.getFullYear()} ${updatetimeorg.getHours() > 9 ? updatetimeorg.getHours() : '0' + updatetimeorg.getHours()}:${updatetimeorg.getMinutes() > 9 ? updatetimeorg.getMinutes() : '0' + updatetimeorg.getMinutes()}:${updatetimeorg.getSeconds() > 9 ? updatetimeorg.getSeconds() : '0' + updatetimeorg.getSeconds()}`
                                    const createtime = `${(createtimeorg.getDate() > 9) ? createtimeorg.getDate() : '0' + createtimeorg.getDate()}/${(createtimeorg.getMonth() > 8) ? createtimeorg.getMonth() + 1 : '0' + (createtimeorg.getMonth() + 1)}/${createtimeorg.getFullYear()} ${createtimeorg.getHours() > 9 ? createtimeorg.getHours() : '0' + createtimeorg.getHours()}:${createtimeorg.getMinutes() > 9 ? createtimeorg.getMinutes() : '0' + createtimeorg.getMinutes()}:${createtimeorg.getSeconds() > 9 ? createtimeorg.getSeconds() : '0' + createtimeorg.getSeconds()}`

                                    var customclass = "";
                                    if (index % 2 === 0) {
                                        customclass = "darkishere"
                                    }
                                    if (e.amount) {
                                        return (
                                            <tr className={`minimum_width_ex ${customclass}`}>
                                                <td >₹{((e.amount / 100)).toLocaleString("en-IN")}</td>
                                                <td>{e.status}</td>
                                                <td>{e.type}</td>
                                                <td>{e.service}</td>
                                                <td className='pointerhere' onClick={(f) => { setTransactionDetail(e.txn_id); get_transaction_details(e.txn_id) }}>{e.order_id}</td>
                                                <td >{e.rrn}</td>
                                                <td style={{ fontSize: "11px" }}>{updatetime}<br />{createtime}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </table>
                        <div style={{ display: "none" }} id='loadinganimation' class="loader-line"></div>
                    </div>
                </div>
                <div className="childAccount1">
                </div>
            </div>
            {/* <style>
                {
                    `
                    .parentTransaction{
                        color: black;
                        margin-left: 265px;
                        border-radius: 30px 0px 0px 30px;
                        // height: 100vh;
                    }
                    .childTransaction{
                        display:flex;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .childTransaction table,tr{
                        margin: 10px;
                        width: 100%;
                    }
                    label{
                        background: #ffffff;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 10px;
                        color: black;
                    }
                    td{
                        background: #ffffff;
                        padding: 20px;
                    }
                    table, th, td,tr{
                        border-spacing: 0;
                        border-collapse: collapse;
                        background: #ffffff;
                    }
                    select, input{
                        color: #6d7782;
                        padding: 5px;
                        border-radius: 10px;
                    }
                    .buttonset{
                        text-align: center;
                        padding: 10px;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .buttonset button{
                        margin: 10px;
                        background-color: #edefef;
                        text-align: center;
                        color: #d85539;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button{
                        background-color: #edefef;
                        text-align: center;
                        color: #6a7580;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .buttonset button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .blockside{
                        text-align: left;
                        background: #ffffff;
                        margin: 10px;
                        border-radius: 10px;
                        display: block;
                        // border: 1px solid #6a7580;
                    }
                    input{
                        color-scheme: light;
                    }
                    select, input{
                        background-color: white;
                        padding: 10px;
                        border: 1px solid #6a7580;
                    }
                    .tran_table div, table, tr, th,td{
                        padding: 3px;
                        background-color: #ffffff;
                    }
                    .tran_table div{
                        border-radius: 10px;
                    }
                    .tran_table{
                        overflow-y: scroll;
                        height: 50vh;
                        padding: 10px;
                        background-color: #ffffff;
                        text-align: center;
                        border-radius: 10px;
                        margin: 15px;
                        margin-top: 20px;
                    }
                    .tran_table::-webkit-scrollbar{
                        display: none;
                    }
                    .tran_table table{
                        width: 100%;   
                    }
                    .tran_table td,th{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    .tran_table th{
                        background-color: #d85539;
                        color: white;
                    }
                    .tran_table select{
                        padding: 5px;
                    }

                    .date{
                        margin-left: 40%;
                    }
                    .rowscount{
                        text-align: right;
                    }


                    .parentPopup {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.8);
                        color: white;
                        z-index: 1;
                    }
                    .childPopup{
                        color: white;
                        z-index: 0;
                        position: fixed; 
                        top: 50%; left:50%;
                        width: 70%;
                        text-align: center;
                        transform:translate(-50%,-50%);
                        border-radius: 10px;
                        display: flex;
                        text-align: left;
                        flex-shrink: 0;
                        // flex-wrap: wrap;
                        align-items: center;
                        margin: 10px;
                        padding: 10px;
                        background: #ffffff;
                        z-index: 2;
                    }
                    .childPopup table{
                        width: 100%;
                    }
                    .childPopup div, label{
                        background: #ffffff;
                    }
                    .close{
                        display: fixed;
                    }
                    .childPopup select{
                        color: black;
                        border-radius: 10px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }
                    `
                }
            </style> */}
        </>
    )
}


export default Merchanttransaction