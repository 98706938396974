import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { LuRefreshCw } from "react-icons/lu";


const Transaction = (props) => {
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getFilter, setFilter] = useState({})
    const [getRow, setRow] = useState(50)
    const [getTransaction, setTransaction] = useState([{}])
    const [getUpdateStatus, setUpdateStatus] = useState("")
    const [getUpdateTxn, setUpdateTxn] = useState("")
    const [getTransactionDetail, setTransactionDetail] = useState("")
    const [getTransactionDetails, setTransactionDetails] = useState({})
    const [getViewpopup, setViewpopup] = useState("")
    const [getTransitionflag, setTransitionflag] = useState(true)
    const [getsuccessfailflag, setsuccessfailflag] = useState("")
    const cookie = new Cookies()




    const get_merchant = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_transaction_details = async (order_id) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_transaction_details`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    order_id: order_id
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionDetails(dataset.data.data[0])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const manual_status_change = async (txn_id, status) => {
        try {
            const remark = document.getElementById("remark_rrn").value
            if (remark === "") {
                alert("remark empty")
            } else {
                props.setLoading("loading")
                const resp = await fetch(`${process.env.REACT_APP_URL}/admin/manual_status_change`, {
                    method: 'POST',
                    body: JSON.stringify({
                        token: cookie.get('user'),
                        txn_id: txn_id,
                        status: status,
                        remark: remark
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
                if (resp.status === 200) {
                    const dataset = await resp.json()
                    if (dataset.status === true) {
                        get_transaction_details(txn_id)
                        setViewpopup("")
                        props.setLoading("success")
                        get_transaction()
                    } else {
                        props.setLoading("fail")
                    }
                } else {
                    props.setLoading("fail")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }




    const get_transaction = async () => {
        try {
            setTransaction([{}])
            document.getElementById("loadinganimation").style.display = 'block'
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_transaction`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: getRow,
                    filter: ffilter
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransaction(dataset.data.data)
                    document.getElementById("loadinganimation").style.display = 'none'
                } else {
                    document.getElementById("loadinganimation").style.display = 'none'
                }
            } else {
                document.getElementById("loadinganimation").style.display = 'none'
            }
        } catch (error) {
            console.log(error)
        }
    }


    const check_status_update = async (order_id) => {
        try {
            var button = document.querySelector('#checkstatusbtn');
            var spinner = '<span class="spinner" style="margin-left: 65px"></span>';
            button.classList.add('loading');
            button.innerHTML = spinner;
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/check_status_update`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    order_id: order_id
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionDetails(dataset.data.data[0])
                    get_transaction()
                    var button = document.querySelector('#checkstatusbtn');
                    button.classList.remove('loading');
                    button.innerHTML = "Check Status";
                } else {
                    var button = document.querySelector('#checkstatusbtn');
                    button.classList.remove('loading');
                    button.innerHTML = "Check Status";
                }
            } else {
                var button = document.querySelector('#checkstatusbtn');
                button.classList.remove('loading');
                button.innerHTML = "Check Status";
            }
        } catch (error) {
            console.log(error)
        }
    }



    const PopupAcq = () => {
        if (getTransactionDetail !== "") {
            return (
                <>
                    <div className="parentPopup" onClick={(e) => { setTransitionflag(true); setTransactionDetail(""); setTransactionDetails({}) }}>
                    </div>
                    <div data-aos={getTransitionflag ? "fade-left" : " "} className="transactionPopup">
                        <div className='popup_header'>
                            <label>
                                {
                                    getMerchantList.map((e, index) => {
                                        if (e.id == getTransactionDetails.merchant_id) {
                                            return (e.name)
                                        }
                                    })
                                }
                            </label>
                        </div>
                        <div className='popup_body'>
                            <label>Business Details</label>
                            <div className='item'>
                                <table className='fieldtable'>
                                    <tr>
                                        <td>Merchant</td>
                                    </tr>
                                    <tr>
                                        <td>Acquirer</td>
                                    </tr>
                                </table>
                                <table className='chikichiki'>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                </table>
                                <table className='valuetable'>
                                    <tr>
                                        <td>
                                            {
                                                getMerchantList.map((e, index) => {
                                                    if (e.id == getTransactionDetails.merchant_id) {
                                                        return (e.name)
                                                    }
                                                })
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {
                                                getAcquirerlist.map((e, index) => {
                                                    if (e.id == getTransactionDetails.route) {
                                                        return (e.name)
                                                    }
                                                })
                                            }
                                        </td>
                                    </tr>
                                </table>
                            </div>

                            <label>Beneficiary Details</label>
                            <div className='item'>
                                <table className='fieldtable'>
                                    <tr>
                                        <td>Name</td>
                                    </tr>
                                    <tr>
                                        <td>Account</td>
                                    </tr>
                                    <tr>
                                        <td>IFSC</td>
                                    </tr>
                                </table>
                                <table className='chikichiki'>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                </table>
                                <table className='valuetable'>
                                    <tr>
                                        <td>{getTransactionDetails.name}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.account}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.ifsc}</td>
                                    </tr>
                                </table>
                            </div>
                            <label>Transaction Details</label>
                            <div className='item'>
                                <table className='fieldtable'>
                                    <tr>
                                        <td>Mode</td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                    </tr>
                                </table>
                                <table className='chikichiki'>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                </table>
                                <table className='valuetable'>
                                    <tr>
                                        <td>{getTransactionDetails.mode}</td>
                                    </tr>
                                    <tr>
                                        <td>₹{(getTransactionDetails.amount / 100).toLocaleString("en-IN")}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.status}</td>
                                    </tr>

                                </table>
                            </div>
                            <label>Order Details</label>
                            <div className='item'>
                                <table className='fieldtable'>
                                    <tr>
                                        <td>Order ID</td>
                                    </tr>
                                    <tr>
                                        <td>Txn ID</td>
                                    </tr>
                                    <tr>
                                        <td>Ref ID</td>
                                    </tr>
                                    <tr>
                                        <td>RRN</td>
                                    </tr>
                                    <tr>
                                        <td>Error</td>
                                    </tr>
                                </table>
                                <table className='chikichiki'>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                    <tr>
                                        <td>:</td>
                                    </tr>
                                </table>
                                <table className='valuetable'>
                                    <tr>
                                        <td>{getTransactionDetails.order_id}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.txn_id}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.ref_id}</td>
                                    </tr>
                                    <tr>
                                        <td>{getTransactionDetails.rrn}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: '10px' }}>{getTransactionDetails.error}</td>
                                    </tr>
                                </table>
                            </div>
                            {(getTransactionDetails.service === "Admin Debit" || getTransactionDetails.service === "Admin Credit" || getTransactionDetails.type !== "debit") ? <></> :
                                <div className='twobtn'>
                                    <button className='failbtn' onClick={(f) => { setsuccessfailflag("FAILED"); setTransitionflag(false); setViewpopup(getTransactionDetails.order_id) }} >Failed</button>
                                    <button className='successbtn' onClick={(f) => { setsuccessfailflag("SUCCESS"); setTransitionflag(false); setViewpopup(getTransactionDetails.order_id) }} >Success</button>
                                </div>
                            }
                            {(getTransactionDetails.service === "Admin Debit" || getTransactionDetails.service === "Admin Credit" || getTransactionDetails.status !== "PENDING") ? <></> :
                                <div className='widebtn'>
                                    <button className='checkbtn' id='checkstatusbtn' onClick={(f) => { check_status_update(getTransactionDetails.order_id) }}>Check Status</button>
                                </div>
                            }
                        </div>
                    </div>
                </>
            )
        }
    }


    const PopupChangests = () => {
        if (getViewpopup) {
            return (
                <>
                    <div className="parentPopup1" onClick={(e) => { setViewpopup("") }}>
                    </div>
                    <div className="childPopup1">
                        <table>
                            <tr>
                                <td>
                                    <label>Status:</label>
                                </td>
                                <td>
                                    <input value={getsuccessfailflag} id="customer_amount" placeholder="Enter Amount" disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Order ID:</label>
                                </td>
                                <td>
                                    <input value={getTransactionDetails.order_id} id="customer_amount" placeholder="Enter Amount" disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Txn ID:</label>
                                </td>
                                <td>
                                    <input value={getTransactionDetails.txn_id} id="customer_amount" placeholder="Enter Amount" disabled />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Remark:</label>
                                </td>
                                <td>
                                    <input id="remark_rrn" placeholder="Enter RRN/Remark" />
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button onClick={(e) => { manual_status_change(getTransactionDetails.txn_id, getsuccessfailflag) }} className='tablebtn'>Transfer</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </>
            )
        }
    }




    useEffect(() => {
        // props.check_cookie()
        get_acquirer()
        get_merchant()
        get_transaction()

    }, [])

    const [isActive, setActive] = useState(true);
    const [defaulttext, setDefaulttext] = useState("View Options")

    function showOptions() {
        setFilter({})
        let height_transition_div = document.getElementById('height_transition_div');
        height_transition_div.classList.toggle('show_div')
        let show_div = document.getElementById('height_transition_div_here');
        show_div.classList.toggle('show_div_here')
        setActive(!isActive)
        setDefaulttext(!defaulttext)
    }


    return (
        <>
            <PopupChangests />
            < PopupAcq />
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="right_dropdown_icon_container">
                        <button className='clearbtndisplay' >
                            <LuRefreshCw className='transferbtn' onClick={(e) => { get_transaction() }} />
                        </button>
                        <button onClick={showOptions}>{defaulttext ? "View Options" : 'Close Options'} {isActive ? <IoIosArrowDropdown className='dropdown_icon' /> : <IoIosArrowDropup className='dropdown_icon' />}</button>
                    </div>
                    <div id='height_transition_div_here' className='height_transition_div_here'>
                        <td>
                            <label>Transaction ID:</label>
                        </td>
                        <td>
                            <input name="txn_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Transaction ID" />
                        </td>
                        <td>
                            <label>Order ID:</label>
                        </td>
                        <td>
                            <input name="order_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Order ID" />
                        </td>
                        <td>
                            <label>RRN:</label>
                        </td>
                        <td>
                            <input name="rrn" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter RRN/UTR" />
                        </td>
                        <td>
                            <button onClick={(e) => { get_transaction(); }}>Search</button>
                        </td>
                    </div>
                    <div className='height_transition_div' id='height_transition_div'>
                        <div className="childTransaction">
                            {/* <PopupView /> */}
                            <table>
                                <tr>
                                    <td>
                                        <label>Acquirer:</label>
                                    </td>
                                    <td>
                                        <select name="route" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                            <option>Select Acquirer</option>
                                            {
                                                getAcquirerlist.map((e, index) => {
                                                    return (
                                                        <option value={e.id}>{e.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Status:</label>
                                    </td>
                                    <td>
                                        <select name="status" onChange={(e) => { console.log("running"); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                            <option>Status Type</option>
                                            <option value="SUCCESS">SUCCESS</option>
                                            <option value="PENDING">PENDING</option>
                                            <option value="FAILED">FAILED</option>
                                            <option value="QUEUED">QUEUED</option>
                                            <option value="INITIATED">INITIATED</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Amount:</label>
                                    </td>
                                    <td>
                                        <input name="amount" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value * 100 } }) }} placeholder="Enter Amount" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Account Number:</label>
                                    </td>
                                    <td>
                                        <input name="bene_account" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Account" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>IFSC:</label>
                                    </td>
                                    <td>
                                        <input name="bene_ifsc" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter IFSC Code" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>RRN:</label>
                                    </td>
                                    <td>
                                        <input name="rrn" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter RRN/UTR" />
                                    </td>
                                </tr>
                            </table>
                            <table>
                                <tr>
                                    <td>
                                        <label>Merchant:</label>
                                    </td>
                                    <td>
                                        <select name="merchant_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                            <option>Select Merchant</option>
                                            {
                                                getMerchantList.map((e, index) => {
                                                    return (
                                                        <option value={e.id}>{e.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Transaction ID:</label>
                                    </td>
                                    <td>
                                        <input name="txn_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Transaction ID" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Order ID:</label>
                                    </td>
                                    <td>
                                        <input name="order_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Order ID" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Reference ID:</label>
                                    </td>
                                    <td>
                                        <input name="ref_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Reference ID" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>From:</label>
                                    </td>
                                    <td>
                                        <input name="created_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $gte: newDate.getTime() } }) }} type="Date" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>To:</label>
                                    </td>
                                    <td>
                                        <input name="created_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $lte: newDate.getTime() + 86399999 } }) }} type="Date" />
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="buttonset">
                            <button onClick={(e) => { get_transaction(); }}>Search Transaction</button>
                        </div>
                    </div>
                </div>
                <div className="rowscount">
                    <select onBlur={(e) => { get_transaction(); }} onChange={(e) => { setRow(e.target.value); }}>
                        <option value={50}>50 Rows</option>
                        <option value={100}>100 Rows</option>
                        <option value={500}>500 Rows</option>
                        <option value={1000}>1000 Rows</option>
                        <option value={5000}>5000 Rows</option>
                    </select>
                </div>
                <div className="tran_table">
                    <div>
                        <table>
                            <tr>
                                <th>Amount</th>
                                <th>Merchant</th>
                                <th>Acquirer</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>Service</th>
                                <th>Order ID</th>
                                <th>UTR/RRN</th>
                                <th>Date & Time</th>
                            </tr>
                            {
                                getTransaction.map((e, index) => {
                                    const updatetimeorg = new Date(e.updated_time)
                                    const createtimeorg = new Date(e.created_time)
                                    const updatetime = `${(updatetimeorg.getDate() > 9) ? updatetimeorg.getDate() : '0' + updatetimeorg.getDate()}/${(updatetimeorg.getMonth() > 8) ? updatetimeorg.getMonth() + 1 : '0' + (updatetimeorg.getMonth() + 1)}/${updatetimeorg.getFullYear()} ${updatetimeorg.getHours() > 9 ? updatetimeorg.getHours() : '0' + updatetimeorg.getHours()}:${updatetimeorg.getMinutes() > 9 ? updatetimeorg.getMinutes() : '0' + updatetimeorg.getMinutes()}:${updatetimeorg.getSeconds() > 9 ? updatetimeorg.getSeconds() : '0' + updatetimeorg.getSeconds()}`
                                    const createtime = `${(createtimeorg.getDate() > 9) ? createtimeorg.getDate() : '0' + createtimeorg.getDate()}/${(createtimeorg.getMonth() > 8) ? createtimeorg.getMonth() + 1 : '0' + (createtimeorg.getMonth() + 1)}/${createtimeorg.getFullYear()} ${createtimeorg.getHours() > 9 ? createtimeorg.getHours() : '0' + createtimeorg.getHours()}:${createtimeorg.getMinutes() > 9 ? createtimeorg.getMinutes() : '0' + createtimeorg.getMinutes()}:${createtimeorg.getSeconds() > 9 ? createtimeorg.getSeconds() : '0' + createtimeorg.getSeconds()}`

                                    var customclass = "";
                                    if (index % 2 === 0) {
                                        customclass = "darkishere"
                                    }
                                    if (e.amount) {
                                        return (
                                            <tr className={`minimum_width_ex ${customclass}`}>
                                                <td >₹{((e.amount / 100)).toLocaleString("en-IN")}</td>
                                                <td >
                                                    {
                                                        getMerchantList.map((ee, index) => {
                                                            if (ee.id == e.merchant_id) {
                                                                return ee.name
                                                            }
                                                        })
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        getAcquirerlist.map((ee, index) => {
                                                            if (ee.id == e.route) {
                                                                return ee.name
                                                            } else if ((e.service === "Admin Debit" || e.service === "Admin Credit") && getAcquirerlist.length - 1 == index) {
                                                                return "ADMIN";
                                                            }
                                                        })
                                                    }
                                                </td>
                                                <td>{e.status}</td>
                                                <td>{e.type}</td>
                                                <td>{e.service}</td>
                                                <td className='pointerhere' onClick={(f) => { setTransitionflag(true); setTransactionDetail(e.txn_id); get_transaction_details(e.txn_id) }}>{e.order_id}</td>
                                                <td >{e.rrn}</td>
                                                <td style={{ fontSize: "11px" }}>{updatetime}<br />{createtime}</td>
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </table>
                        <div style={{ display: "none" }} id='loadinganimation' class="loader-line"></div>
                    </div>
                </div>
                <div className="childAccount1">
                </div>
            </div >
            {/* <style>
                {
                    `
                    .parentTransaction{
                        color: black;
                        background: #f7f6fa;
                    }
                    .childTransaction{
                        display:flex;
                        background: #ffffff;
                    }
                    .childTransaction table,tr{
                        margin: 10px;
                        width: 100%;
                    }
                    label{
                        background: #ffffff;
                        font-size: 17px;
                        margin: 10px;
                        color: #adadad;
                    }
                    td{
                        background: #ffffff;
                        padding: 20px;
                    }
                    table, th, td,tr{
                        border-spacing: 0;
                        border-collapse: collapse;
                        background: #ffffff;
                    }
                    select, input{
                        color: #6d7782;
                        padding: 3px 5px;
                        border-radius: 4px;
                        // border: 1.5px solid #adadad;
                        text-align: center;
                        min-width: 177px;
                    }

                    input:focus {
                        outline: none;
                        border: 2px solid #a74dfd;
                    }

                    select:focus {
                        outline: none;
                    }

                    .buttonset{
                        text-align: center;
                        padding: 10px;
                        background: #ffffff;
                    }

                    button{
                        margin: 10px;
                        background: linear-gradient(to bottom, #a74dfd, #9c40fe, #8a34fe);
                        text-align: center;
                        color: white;
                        font-size: 16px;
                        padding: 8px 10px;
                        border-radius: 4px;
                        box-shadow: 3px 4px 12px #8a34fe;
                        border: none;
                        outline: none;
                    }
    
                    button:hover{
                        color: #cccaca;
                        cursor: pointer
                    }

                    .tablebtn {
                        padding: 4px 6px;
                    }
                    .blockside{
                        text-align: left;
                        background: #ffffff;
                        margin: 10px auto 10px auto;
                        display: block;
                        // border: 1px solid #6a7580;
                        max-width: 98%;
                    }
                    input{
                        color-scheme: light;
                    }
                    select, input{
                        background-color: white;
                        padding: 10px;
                        border: 1px solid #6a7580;
                    }
                    .tran_table div, table, tr, th,td{
                        padding: 3px;
                        background-color: #ffffff;
                    }
                    .tran_table{
                        overflow-y: scroll;
                        padding: 10px;
                        background-color: #ffffff;
                        text-align: center;
                        margin-top: 2px;
                        max-width: 98%;
                        margin: 2px auto 2px auto;
                    }
                    .tran_table::-webkit-scrollbar{
                        display: none;
                    }
                    .tran_table table{
                        width: 100%;   
                        padding: 20px 0px;
                    }
                    .tran_table td,th{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    .tran_table th{
                        background-color:  #8a34fe;
                        color: white;
                        padding: 6px 0px;
                        box-shadow: 3px 4px 12px #8a34fe;
                    }

                    .tran_table tr:nth-child(2) {
                        margin-top: 20px;
                    }
                    .tran_table select{
                        padding: 5px;
                    }

                    .date{
                        margin-left: 40%;
                    }
                    .rowscount{
                        text-align: right;
                        margin: 30px 20px 0px 0px;
                    }


                    .parentPopup {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.8);
                        color: white;
                        z-index: 1;
                    }
                    .childPopup{
                        color: white;
                        z-index: 0;
                        position: fixed; 
                        top: 50%; left:50%;
                        width: 70%;
                        text-align: center;
                        transform:translate(-50%,-50%);
                        border-radius: 10px;
                        display: flex;
                        text-align: left;
                        flex-shrink: 0;
                        // flex-wrap: wrap;
                        align-items: center;
                        margin: 10px;
                        padding: 10px;
                        background: #ffffff;
                        z-index: 2;
                    }
                    .childPopup table{
                        width: 100%;
                    }
                    .childPopup div, label{
                        background: #ffffff;
                    }
                    .close{
                        display: fixed;
                    }
                    .childPopup select{
                        color: black;
                        border-radius: 10px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }
                    `
                }
                `
            </style> */}
        </>
    )
}


export default Transaction