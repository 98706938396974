import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import { useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const Dashboard = (props) => {
    const dd = new Date();
    const [getBalance, setBalance] = useState([{}])
    const [getChartTick, setChartTick] = useState([])
    const [getDashboardData, setDashboardData] = useState({})
    const [getWalletbal, setWalletbal] = useState()


    const [getChartData, setChartData] = useState([])
    const [getChartData1, setChartData1] = useState([])
    const [getSuccessCount, setSuccessCount] = useState(12)
    const [getPendingCount, setPendingCount] = useState(12)
    const [getFailedCount, setFailedCount] = useState(12)
    const [getSuccessAmount, setSuccessAmount] = useState(12)
    const [getPendingAmount, setPendingAmount] = useState(12)
    const [getFailedAmount, setFailedAmount] = useState(12)
    const cookie = new Cookies()


    var LineData1 = [];

    const update_all_values = async () => {
        try {
            setSuccessCount(0)
            setSuccessAmount(0)
            setPendingCount(0)
            setPendingAmount(0)
            setFailedCount(0)
            setFailedAmount(0)
            for (let i = 0; i < getDashboardData.length; i++) {
                if (getDashboardData[i]._id === "SUCCESS") {
                    setSuccessCount(getDashboardData[i].number)
                    setSuccessAmount(getDashboardData[i].total)
                }
                if (getDashboardData[i]._id === "PENDING") {
                    setPendingCount(getDashboardData[i].number)
                    setPendingAmount(getDashboardData[i].total)
                }
                if (getDashboardData[i]._id === "FAILED") {
                    setFailedCount(getDashboardData[i].number)
                    setFailedAmount(getDashboardData[i].total)
                }
            }
            for (let i = 0; i < getChartTick.length; i++) {
                let hello = { date: new Date(getChartTick[i].time + 21600000).toISOString().split('T')[0], value: 0 }
                for (let j = 0; j < getChartTick[i].chart_tick.length; j++) {
                    const state = getChartTick[i].chart_tick[j]._id
                    const total = getChartTick[i].chart_tick[j].total
                    if (state === "SUCCESS") {
                        hello.value = (total / 100)
                    }
                }
                LineData1[i] = hello
            }
            setChartData1(LineData1)
        } catch (error) {
            console.log(error)
        }
    }

    const get_dashboard_value = async () => {
        try {
            props.setLoading("loading")
            var temp = {}
            try {
                var myDate1 = document.getElementById("starttimedash").value; myDate1 = myDate1.split("-"); console.log(myDate1); var newDate1 = new Date(myDate1[0], myDate1[1] - 1, myDate1[2]);
                var myDate2 = document.getElementById("endtimedash").value; myDate2 = myDate2.split("-"); console.log(myDate2); var newDate2 = new Date(myDate2[0], myDate2[1] - 1, myDate2[2]);
                const tempp = {
                    $gte: newDate1.getTime(),
                    $lte: newDate2.getTime() + 86399999
                }
                temp.created_time = tempp
            } catch (error) {
                console.log(error)
                console.log("runningg")
                const tempp = {
                    $gte: dd.getTime() - ((dd.getTime() + 19800000) % 86400000),
                    $lte: (dd.getTime() - (dd.getTime() + 19800000) % 86400000) + 86400000
                }
                temp.created_time = tempp
            }
            if (document.getElementById("starttimedash").value === "" || document.getElementById("endtimedash").value === "") {
                console.log("runningg")
                const tempp = {
                    $gte: dd.getTime() - ((dd.getTime() + 19800000) % 86400000),
                    $lte: (dd.getTime() - (dd.getTime() + 19800000) % 86400000) + 86400000
                }
                temp.created_time = tempp
            }
            console.log(temp)
            const get_dashboard_value = await fetch(`${process.env.REACT_APP_URL}/admin/dashboard_value`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    filter: temp
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            const dataset = await get_dashboard_value.json()
            if (dataset.status === true) {
                props.setLoading("success")
                setChartTick(dataset.data.chart)
                setDashboardData(dataset.data.data)
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }

    function Chart(props) {
        useLayoutEffect(() => {
            let root = am5.Root.new("chartdiv");

            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    focusable: true,
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    pinchZoomX: true,
                    paddingLeft: 0
                })
            );

            var data = props.data

            var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                maxDeviation: 0.1,
                groupData: false,
                baseInterval: {
                    timeUnit: "day",
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {
                    minorGridEnabled: true,
                    minGridDistance: 70
                }),
                tooltip: am5.Tooltip.new(root, {})
            }));

            xAxis.data.setAll(data);


            // Create Y-axis
            var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0.2,
                renderer: am5xy.AxisRendererY.new(root, {})
            }));


            // Create series
            var series = chart.series.push(am5xy.LineSeries.new(root, {
                minBulletDistance: 10,
                connect: false,
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                valueXField: "date",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "horizontal",
                    labelText: "{valueY}"
                })
            }));
            series.fills.template.setAll({
                fillOpacity: 0.2,
                visible: true
            });

            series.strokes.template.setAll({
                strokeWidth: 2
            });
            series.data.processor = am5.DataProcessor.new(root, {
                dateFormat: "yyyy-MM-dd",
                dateFields: ["date"]
            });
            series.data.setAll(data);
            series.bullets.push(function () {
                var circle = am5.Circle.new(root, {
                    radius: 4,
                    fill: root.interfaceColors.get("background"),
                    stroke: series.get("fill"),
                    strokeWidth: 2
                })

                return am5.Bullet.new(root, {
                    sprite: circle
                })
            });
            // series1.data.setAll(data);
            var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
                xAxis: xAxis,
                behavior: "none"
            }));
            cursor.lineY.set("visible", false);

            chart.set("scrollbarX", am5.Scrollbar.new(root, {
                orientation: "horizontal"
            }));
            // Add legend
            let legend = chart.children.push(am5.Legend.new(root, {}));
            legend.data.setAll(chart.series.values);

            // Add cursor
            chart.set("cursor", am5xy.XYCursor.new(root, {}));

            return () => {
                root.dispose();
            };
        }, []);

        return (
            <div id="chartdiv" style={{ width: "100%", height: "525px" }}></div>
        );
    }

    useEffect(() => {
        props.check_cookie()
        // get_balance()
        get_dashboard_value()
    }, [])
    useEffect(() => {

        update_all_values()
    }, [getChartTick])

    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside" style={{ margin: "10px" }}>
                    <div className="childTransaction" >

                        <table style={{ textAlign: "right" }}>
                            <tr>
                                <td>
                                    <input name="created_time" id="starttimedash" type="Date" className="mr-4" />
                                    <input name="created_time" id="endtimedash" type="Date" />
                                    <button onClick={(e) => { get_dashboard_value() }}>Apply</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="dashboardTop">
                        <div className="topCard">
                            <div className="leftpart">
                                <span class="material-icons">
                                    currency_rupee
                                </span>
                                {/* <p>Data of {parseInt((getDashboardFilter.created_time.$lte - getDashboardFilter.created_time.$gte) / 86400000)} Days</p> */}
                            </div>
                            <div className="rightpart">
                                <p>{((getSuccessAmount + getPendingAmount + getFailedAmount) / 100).toLocaleString("en-IN")}</p>
                                <span>{(getSuccessCount + getPendingCount + getFailedCount)}</span>
                                <br />
                                <span>{(getSuccessCount + getPendingCount + getFailedCount) / (getSuccessCount + getPendingCount + getFailedCount) * 100}%</span>
                            </div>
                        </div>
                        <div className="topCard">
                            <div className="leftpart">
                                <span class="material-icons">
                                    thumb_up_alt
                                </span>
                                {/* <p>Data of {parseInt((getDashboardFilter.created_time.$lte - getDashboardFilter.created_time.$gte) / 86400000)} Days</p> */}
                            </div>
                            <div className="rightpart">
                                <p>{(getSuccessAmount / 100).toLocaleString("en-IN")}</p>
                                <span>{getSuccessCount}</span>
                                <br />
                                <span>{parseInt(getSuccessCount / (getSuccessCount + getPendingCount + getFailedCount) * 100)}%</span>
                            </div>
                            {/* success */}
                        </div>
                        <div className="topCard">
                            <div className="leftpart">
                                <span class="material-icons">
                                    sms_failed
                                </span>
                                {/* <p>Data of {parseInt((getDashboardFilter.created_time.$lte - getDashboardFilter.created_time.$gte) / 86400000)} Days</p> */}
                            </div>
                            <div className="rightpart">
                                <p>{(getFailedAmount / 100).toLocaleString("en-IN")}</p>
                                <span>{getFailedCount}</span>
                                <br />
                                <span>{parseInt(getFailedCount / (getSuccessCount + getPendingCount + getFailedCount) * 100)}%</span>
                            </div>
                        </div>
                        <div className="topCard">
                            <div className="leftpart">
                                <span class="material-icons">
                                    scatter_plot
                                </span>
                                {/* <p>Data of {parseInt((getDashboardFilter.created_time.$lte - getDashboardFilter.created_time.$gte) / 86400000)} Days</p> */}
                            </div>
                            <div className="rightpart">
                                <p>{(getPendingAmount / 100).toLocaleString("en-IN")}</p>
                                <span>{getPendingCount}</span>
                                <br />
                                <span>{parseInt(getPendingCount / (getSuccessCount + getPendingCount + getFailedCount) * 100)}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tabletie">
                    <Chart data={getChartData1} />
                </div>
            </div>

            <style>
                {`
                 .dashboardTop{
                    display: flex;
                }
                .topCard{
                    color: black;
                    margin: 10px;
                    width: 100%;
                    background-color: #ffffff;
                    padding: 20px;
                    border-radius: 10px;
                    display: flex;
                }
                .leftpart{
                    text-align: left;
                    display: block;
                    width: 100%;
                    background-color: #ffffff;
                    font-size: 12px;
                }
                .leftpart span{
                    background-color: #edeae7;
                    padding: 12px;
                    border-radius: 10px;
                    color: black;
                }
                .leftpart p{
                    margin-top: 10px;
                    background-color: #ffffff;
                }
                .rightpart{
                    display: block;
                    text-align: right;
                    background-color: #ffffff;
                    font-size: 12px;
                }
                .rightpart span{
                    background-color: #ffffff;
                }
                .rightpart p{
                    font-size: 20px;
                    background-color: #ffffff;
                }

                .parentTransaction{
                    color: black;
                    // height: 100vh;
                }
                .childTransaction{
                    display:flex;
                    border-radius: 10px;
                    background: #ffffff;
                }
                .childTransaction table,tr{
                    margin: 10px;
                    width: 100%;
                }
                label{
                    background: #ffffff;
                    font-size: 18px;
                    font-weight: 600;
                    margin: 10px;
                    color: black;
                }
                td{
                    background: #ffffff;
                    padding: 20px;
                }
                table, th, td,tr{
                    border-spacing: 0;
                    border-collapse: collapse;
                    background: #ffffff;
                }

                .blockside{
                    text-align: left;
                    background: #ffffff;
                    margin: 10px;
                    display: block;
                    padding-top: 0px !important;
                }
                .tran_table div, table, tr, th,td{
                    padding: 3px;
                    background-color: #ffffff;
                }
                .tran_table div{
                    border-radius: 10px;
                }
                .tran_table{
                    overflow-y: scroll;
                    height: 50vh;
                    padding: 10px;
                    background-color: #ffffff;
                    text-align: center;
                    border-radius: 10px;
                    margin: 15px;
                    margin-top: 20px;
                }
                .tran_table::-webkit-scrollbar{
                    display: none;
                }
                .tran_table table{
                    width: 100%;   
                }
                .tran_table td,th{
                    padding-right: 20px;
                    padding-left: 20px;
                }
                .tran_table th{
                    background-color: #d85539;
                    color: white;
                }
                .tran_table select{
                    padding: 5px;
                }

                .date{
                    margin-left: 40%;
                }
                .rowscount{
                    text-align: right;
                }


                .parentPopup {
                    position: fixed;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.8);
                    color: white;
                    z-index: 1;
                }
                .childPopup{
                    color: white;
                    z-index: 0;
                    position: fixed; 
                    top: 50%; left:50%;
                    width: 70%;
                    text-align: center;
                    transform:translate(-50%,-50%);
                    border-radius: 10px;
                    display: flex;
                    text-align: left;
                    flex-shrink: 0;
                    // flex-wrap: wrap;
                    align-items: center;
                    margin: 10px;
                    padding: 10px;
                    background: #ffffff;
                    z-index: 2;
                }
                .childPopup table{
                    width: 100%;
                }
                .childPopup div, label{
                    background: #ffffff;
                }
                .close{
                    display: fixed;
                }
                .childPopup select{
                    color: black;
                    border-radius: 10px;
                    margin-left: 10px;
                    margin-top: 10px;
                }
                `}
            </style>
        </>
    )
}

export default Dashboard