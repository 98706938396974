import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import * as XLSX from "xlsx";
import '../css/Common.css';
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";


const Bulkcms = (props) => {
    const [getRoute, setRoute] = useState([{}])
    const [getCurrRoute, setCurrRoute] = useState("")
    const cookie = new Cookies()
    const [data, setData] = useState([]);
    const [getFile, setFile] = useState(null)

    const [getFileName, setFileName] = useState("")
    const [getFileType, setFileType] = useState("")


    const [getLoadCount, setLoadCount] = useState([])

    const [getRunning, setRunning] = useState(false)

    const get_route = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_route`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setRoute(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const upload_cms_file = async () => {
        const reader = new FileReader();
        reader.onloadend = async (file) => {
            const base64File = reader.result.split(',')[1];
            try {
                if (getCurrRoute == "axis_cms") {
                    if (getFileType == "application/vnd.ms-excel") {
                        setRunning(true)
                        const resp = await fetch(`${process.env.REACT_APP_URL}/admin/upload_cms_file`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                token: cookie.get('user'),
                                fileName: getFileName,
                                fileType: getFileType,
                                fileData: base64File,
                            }),
                        });
                        if (resp.status == 200) {
                            const dataset = await resp.json();
                            if (dataset.status == true) {
                                let arr = []
                                for (let i = 0; i < data.length; i++) {
                                    const response = await fetch(`${process.env.REACT_APP_URL}/axis_cms_transaction`, {
                                        method: "POST",
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            token: cookie.get('user'),
                                            ...data[i]
                                        })
                                    })
                                    if (response.status == 200) {
                                        arr.push(i)
                                        setLoadCount([...arr])
                                        console.log(arr, getLoadCount, "get load count")
                                    }
                                }
                            }
                        }
                    } else {
                        alert("Invalid File Type")
                    }
                } else {
                    alert("Invalid Route")
                }
            } catch (error) {
                console.log(error);
            }
        };
        reader.readAsDataURL(getFile);
    }





    const handleFileChange = (event) => {
        setRunning(false)
        setLoadCount(0)
        const file = event.target.files[0];
        setFile(event.target.files[0])
        setFileName(file.name)
        setFileType(file.type)
        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            let arr = []
            let enable_flag = false
            for (let i = 0; i < jsonData.length; i++) {
                if (jsonData[i].__EMPTY_12 == "Total") {
                    break;
                }
                if (enable_flag) {
                    arr.push({
                        txn_id: jsonData[i].__EMPTY_18,
                        amount: parseInt((jsonData[i].__EMPTY_16) * 100),
                        txn_status: jsonData[i].__EMPTY_17,
                        error: jsonData[i].__EMPTY_43,
                        utr: jsonData[i].__EMPTY_41,
                        ref_id: jsonData[i].__EMPTY_62
                    })
                }
                if (jsonData[i].__EMPTY == "Sr. No.") {
                    enable_flag = true
                }
            }
            setData(arr);
        };
        reader.readAsBinaryString(file);
    };






    useEffect(() => {
        get_route()
    }, [])


    const [isActive, setActive] = useState(true);
    const [defaulttext, setDefaulttext] = useState("View Options")

    function showOptions() {
        let height_transition_div = document.getElementById('height_transition_div');
        height_transition_div.classList.toggle('show_div')
        setActive(!isActive)
        setDefaulttext(!defaulttext)
    }



    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="right_dropdown_icon_container">
                        <button onClick={showOptions}>{defaulttext ? "View Options" : 'Close Options'} {isActive ? <IoIosArrowDropdown className='dropdown_icon' /> : <IoIosArrowDropup className='dropdown_icon' />}</button>
                    </div>
                    <div className='height_transition_div' id='height_transition_div'>
                        <div className="childTransaction">
                            {/* <PopupView /> */}
                            <table>
                                <tr>
                                    <td>
                                        <label>Route:</label>
                                    </td>
                                    <td>
                                        <select name="route" onChange={(e) => { setCurrRoute(e.target.value); }}>
                                            <option value="">Select Acquirer</option>
                                            {
                                                getRoute.map((e, index) => {
                                                    return (
                                                        <option value={e.name}>{e.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </td>
                                </tr>
                            </table>
                            <table>
                                {
                                    (getCurrRoute != "") ?

                                        <tr>
                                            <td>
                                                <label>Select File:</label>
                                            </td>
                                            <td>
                                                <input type="file" accept=".xls,.xlsx" onChange={handleFileChange} />
                                            </td>

                                        </tr>
                                        : <></>
                                }
                            </table>
                        </div>
                        <div className="buttonset">
                            <button onClick={(e) => { upload_cms_file() }}>Submit</button>
                        </div>
                    </div>
                </div>
                <div style={{ "text-align": "center" }} className="percentagebar">
                    <progress id="file" max={data} value={getLoadCount.length}>hello</progress>
                    <label>{(getLoadCount.length / data.length) * 100}%</label>
                    {((getLoadCount.length / data.length) == 1) ? <label>completed</label> :
                        (getRunning) ? <label>running</label> : <label>not running</label>
                    }
                </div>
                <div className="tran_table">
                    <div>
                        <table>
                            <tr>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Transaction ID</th>
                                <th>Reference ID</th>
                                <th>UTR</th>
                                <th>Error</th>
                            </tr>
                            {
                                data.map((e, map) => {
                                    return (
                                        <tr>
                                            <td>Rs.{(e.amount / 100).toFixed(2)}</td>
                                            <td>{e.txn_status}</td>
                                            <td>{e.txn_id}</td>
                                            <td>{e.ref_id}</td>
                                            <td>{e.utr}</td>
                                            <td>{e.error}</td>
                                        </tr>
                                    )
                                })
                            }
                            {/* {
                                props.getTransaction.map((e, map) => {
                                    let style = {
                                        color: 'white'
                                    }
                                    if (e.status) {
                                        style.color = 'green'
                                    } else {
                                        style.color = 'red'
                                    }
                                    return (
                                        <tr style={style}>
                                            <td>Rs.{(e.amount / 100).toFixed(2)}</td>
                                            <td>{e.account}</td>
                                            <td>{e.ifsc}</td>
                                            <td>{e.order_id}</td>
                                        </tr>
                                    )
                                })
                            } */}
                        </table>
                    </div>
                </div>
            </div>
            {/* <style>
                {
                    `
                    .parentTransaction{
                        color: black;
                        border-radius: 30px 0px 0px 30px;
                        // height: 100vh;
                        background: #f7f6fa;
                    }
                    .childTransaction{
                        display:flex;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .childTransaction table,tr{
                        margin: 10px;
                        width: 100%;
                    }
                    label{
                        background: #ffffff;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 10px;
                        color: black;
                    }
                    td{
                        background: #ffffff;
                        padding: 20px;
                    }
                    table, th, td,tr{
                        border-spacing: 0;
                        border-collapse: collapse;
                        background: #ffffff;
                    }
                    select, input{
                        color: #6d7782;
                        padding: 5px;
                        border-radius: 10px;
                    }
                    .buttonset{
                        text-align: center;
                        padding: 10px;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .buttonset button{
                        margin: 10px;
                        background-color: #edefef;
                        text-align: center;
                        color: #d85539;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button{
                        background-color: #edefef;
                        text-align: center;
                        color: #6a7580;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .buttonset button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .blockside{
                        text-align: left;
                        background: #ffffff;
                        margin: 10px;
                        border-radius: 10px;
                        display: block;
                        // border: 1px solid #6a7580;
                    }
                    input{
                        color-scheme: light;
                    }
                    select, input{
                        background-color: white;
                        padding: 10px;
                        border: 1px solid #6a7580;
                    }
                    .tran_table div, table, tr, th,td{
                        padding: 3px;
                        background-color: #ffffff;
                    }
                    .tran_table div{
                        border-radius: 10px;
                    }
                    .tran_table{
                        overflow-y: scroll;
                        height: 50vh;
                        padding: 10px;
                        background-color: #ffffff;
                        text-align: center;
                        border-radius: 10px;
                        margin: 15px;
                        margin-top: 20px;
                    }
                    .tran_table::-webkit-scrollbar{
                        display: none;
                    }
                    .tran_table table{
                        width: 100%;   
                    }
                    .tran_table td,th{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    .tran_table th{
                        background-color: #d85539;
                        color: white;
                    }
                    .tran_table select{
                        padding: 5px;
                    }

                    .date{
                        margin-left: 40%;
                    }
                    .rowscount{
                        text-align: right;
                    }


                    .parentPopup {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.8);
                        color: white;
                        z-index: 1;
                    }
                    .childPopup{
                        color: white;
                        z-index: 0;
                        position: fixed; 
                        top: 50%; left:50%;
                        width: 70%;
                        text-align: center;
                        transform:translate(-50%,-50%);
                        border-radius: 10px;
                        display: flex;
                        text-align: left;
                        flex-shrink: 0;
                        // flex-wrap: wrap;
                        align-items: center;
                        margin: 10px;
                        padding: 10px;
                        background: #ffffff;
                        z-index: 2;
                    }
                    .childPopup table{
                        width: 100%;
                    }
                    .childPopup div, label{
                        background: #ffffff;
                    }
                    .close{
                        display: fixed;
                    }
                    .childPopup select{
                        color: black;
                        border-radius: 10px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }

                    `
                }
            </style> */}
        </>
    )
}


export default Bulkcms