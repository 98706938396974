import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import "../css/Common.css"
import { LuDownload } from "react-icons/lu";



const Cmsdownload = (props) => {
    const [getReportList, setReportList] = useState([{}])
    const [getAcquirerTranCount, setAcquirerTranCount] = useState([{}])
    const [getSelectAcq, setSelectAcq] = useState([{}])

    const [getTransactioncount, setTransactioncount] = useState(0)


    const cookie = new Cookies()

    const get_acquirer_transaction_count = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/transaction_cms`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerTranCount(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }




    const get_report_list = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_cms_list`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setReportList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const generate_cms = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/cms_generator`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user'),
                    acquirer: getSelectAcq,
                    transaction_count: getTransactioncount
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_report_list()
                }
            }
        } catch (error) {
            console.log(error)
        }
    }



    const download_report = async (report_id) => {
        try {
            document.getElementById("report_id").value = report_id
            document.getElementById("formpost").submit()
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        // props.check_cookie()
        // get_transaction()
        get_report_list()
        get_acquirer_transaction_count()
    }, [])



    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="childTransaction">
                        <table>
                            <tr>
                                <td>
                                    <label>Acquirer:</label>
                                </td>
                                <td>
                                    <select name="route" onChange={(e) => { setSelectAcq(e.target.value); }}>
                                        <option value="">Select Acquirer</option>
                                        {
                                            getAcquirerTranCount.map((e, index) => {
                                                return (
                                                    <option value={e.id}>{e.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            {
                                getSelectAcq != ""
                                    ?
                                    <tr>
                                        <td>
                                            <label>Transaction Count:</label>
                                        </td>
                                        {
                                            getAcquirerTranCount.map((ee, index) => {
                                                if (getSelectAcq == ee.id) {
                                                    return (
                                                        <td>
                                                            <input value={ee.count} />
                                                        </td>
                                                    )
                                                }
                                            })
                                        }
                                    </tr>
                                    :
                                    <tr>
                                        <td>
                                            <label>Transaction Count:</label>
                                        </td>
                                        <td>
                                            <input value={"-"} />
                                        </td>
                                    </tr>
                            }
                        </table>
                        <table>
                            <tr>
                                <td>
                                    <label>No. of Transaction:</label>
                                </td>
                                <td>
                                    <input name="no_tran" onChange={(e) => { setTransactioncount(e.target.value) }} />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="buttonset">
                        <button onClick={(e) => { generate_cms() }}>Generate Table</button>
                    </div>
                </div>
                <div className="tran_table">
                    <div>
                        <table>
                            <tr>
                                <th>Created Time</th>
                                <th>Status</th>
                                <th>Route</th>
                                <th>Acquirer</th>
                                <th>Generated Time</th>
                                <th>Download</th>
                                <th>Ever Downloaded</th>
                            </tr>
                            {
                                getReportList.map((e, index) => {
                                    console.log(e)
                                    const createtimeorg = new Date(e.created_time)
                                    const createtime = `${(createtimeorg.getDate() > 9) ? createtimeorg.getDate() : '0' + createtimeorg.getDate()}/${(createtimeorg.getMonth() > 8) ? createtimeorg.getMonth() + 1 : '0' + (createtimeorg.getMonth() + 1)}/${createtimeorg.getFullYear()} ${createtimeorg.getHours() > 9 ? createtimeorg.getHours() : '0' + createtimeorg.getHours()}:${createtimeorg.getMinutes() > 9 ? createtimeorg.getMinutes() : '0' + createtimeorg.getMinutes()}:${createtimeorg.getSeconds() > 9 ? createtimeorg.getSeconds() : '0' + createtimeorg.getSeconds()}`
                                    var updatetime = ""
                                    if (e.status != 'PENDING' && e.status != 'PROCESSING') {
                                        const updatetimeorg = new Date(e.updated_time)
                                        updatetime = `${(updatetimeorg.getDate() > 9) ? updatetimeorg.getDate() : '0' + updatetimeorg.getDate()}/${(updatetimeorg.getMonth() > 8) ? updatetimeorg.getMonth() + 1 : '0' + (updatetimeorg.getMonth() + 1)}/${updatetimeorg.getFullYear()} ${updatetimeorg.getHours() > 9 ? updatetimeorg.getHours() : '0' + updatetimeorg.getHours()}:${updatetimeorg.getMinutes() > 9 ? updatetimeorg.getMinutes() : '0' + updatetimeorg.getMinutes()}:${updatetimeorg.getSeconds() > 9 ? updatetimeorg.getSeconds() : '0' + updatetimeorg.getSeconds()}`
                                    } 

                                    return (
                                        <tr>
                                            <td>{createtime}</td>
                                            <td>{e.status}</td>
                                            <td>{e.route}</td>
                                            {
                                                getAcquirerTranCount.map((ee, index) => {
                                                    if (e.acquirer_id == ee.id) {
                                                        return <td>{ee.name}</td>
                                                    }
                                                })
                                            }
                                            <td>{updatetime}</td>
                                            {
                                                (e.status != 'PENDING' && e.status != 'PROCESSING') ?
                                                    <td>
                                                        <button className='clearbtndisplay' onClick={(f) => { download_report(e.id) }} >
                                                            <LuDownload className='transferbtn' />
                                                        </button>
                                                    </td> : <></>
                                            }
                                            {
                                                (e.downloaded) ?
                                                    <td>
                                                        YES
                                                    </td> :
                                                    <td>
                                                        NO
                                                    </td>
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </table>
                        <div style={{ display: "none" }} id='loadinganimation' class="loader-line"></div>
                    </div>
                </div>
                <form id="formpost" action={`${process.env.REACT_APP_URL}/admin/download_cms`} target="_blank" method="POST">
                    <input name="token" value={cookie.get('user')} ></input>
                    <input name="report_id" id='report_id'  ></input>
                    <input type="submit"></input>
                </form>
                <div className="childAccount1">
                </div>
            </div >


        </>
    )
}


export default Cmsdownload