import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import '../css/Common.css'
import { LuDownload } from "react-icons/lu";



const Merchantreport = (props) => {
    const [getFilter, setFilter] = useState({})

    const [getReportList, setReportList] = useState([{}])

    const [getHeaderForm, setHeaderForm] = useState(JSON.stringify({}))

    const cookie = new Cookies()


    const get_report_list = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_report_list`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setReportList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const download_report = async (report_id) => {
        try {
            document.getElementById("report_id").value = report_id
            document.getElementById("formpost").submit()
        } catch (error) {
            console.log(error)
        }
    }

    const create_report = async () => {
        try {
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_report`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    filter: JSON.stringify(ffilter)
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_report_list()
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        console.log(cookie.get('merchant'))
        // props.check_cookie()
        get_report_list()
    }, [])




    return (
        <>
            <div className="parentTransaction" data-aos="fade-left">
                <div className="blockside">
                    <div className="childTransaction">
                        {/* <PopupView /> */}
                        <table>
                            <tr>
                                <td>
                                    <label>Status:</label>
                                </td>
                                <td>
                                    <select name="status" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } });  }}>
                                        <option>Status Type</option>
                                        <option value="SUCCESS">SUCCESS</option>
                                        <option value="PENDING">PENDING</option>
                                        <option value="FAILED">FAILED</option>
                                        <option value="QUEUED">QUEUED</option>
                                        <option value="INITIATED">INITIATED</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td>
                                    <label>From:</label>
                                </td>
                                <td>
                                    <input name="created_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $gte: newDate.getTime() } });  }} type="Date" />
                                </td>
                            </tr>
                            <tr>
                                <td><label>To:</label></td>
                                <td>
                                    <input name="created_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $lte: newDate.getTime() + 86399999 } });  }} type="Date" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="buttonset">
                        <button onClick={(e) => { create_report(); }}>Download Report</button>
                    </div>


                </div>
                <div className="tran_table">
                    <div>
                        <table>
                            <tr>
                                <th>Created Time</th>
                                <th>Status</th>
                                <th>Generated Time</th>
                                <th>Download</th>
                            </tr>
                            {
                                getReportList.map((e, index) => {
                                    console.log(e)
                                    const createtimeorg = new Date(e.created_time)
                                    const createtime = `${(createtimeorg.getDate() > 9) ? createtimeorg.getDate() : '0' + createtimeorg.getDate()}/${(createtimeorg.getMonth() > 8) ? createtimeorg.getMonth() + 1 : '0' + (createtimeorg.getMonth() + 1)}/${createtimeorg.getFullYear()} ${createtimeorg.getHours() > 9 ? createtimeorg.getHours() : '0' + createtimeorg.getHours()}:${createtimeorg.getMinutes() > 9 ? createtimeorg.getMinutes() : '0' + createtimeorg.getMinutes()}:${createtimeorg.getSeconds() > 9 ? createtimeorg.getSeconds() : '0' + createtimeorg.getSeconds()}`
                                    var updatetime = ""
                                    if (e.status != 'PENDING' && e.status != 'PROCESSING') {
                                        const updatetimeorg = new Date(e.updated_time)
                                        updatetime = `${(updatetimeorg.getDate() > 9) ? updatetimeorg.getDate() : '0' + updatetimeorg.getDate()}/${(updatetimeorg.getMonth() > 8) ? updatetimeorg.getMonth() + 1 : '0' + (updatetimeorg.getMonth() + 1)}/${updatetimeorg.getFullYear()} ${updatetimeorg.getHours() > 9 ? updatetimeorg.getHours() : '0' + updatetimeorg.getHours()}:${updatetimeorg.getMinutes() > 9 ? updatetimeorg.getMinutes() : '0' + updatetimeorg.getMinutes()}:${updatetimeorg.getSeconds() > 9 ? updatetimeorg.getSeconds() : '0' + updatetimeorg.getSeconds()}`
                                    } else {
                                    }

                                    return (
                                        <tr>
                                            <td>{createtime}</td>
                                            <td>{e.status}</td>
                                            <td>{updatetime}</td>
                                            {
                                                (e.status != 'PENDING' && e.status != 'PROCESSING') ?
                                                    <td>
                                                        <button className='clearbtndisplay' onClick={(f) => { download_report(e.id) }} >
                                                            <LuDownload className='transferbtn' />
                                                        </button>
                                                    </td> : <></>
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </table>
                        <div style={{ display: "none" }} id='loadinganimation' class="loader-line"></div>
                    </div>
                </div>
                <form id="formpost" action={`${process.env.REACT_APP_URL}/merchant/download_report`} target="_blank" method="POST">
                    <input name="token" value={cookie.get('merchant')} ></input>
                    <input name="report_id" id='report_id'  ></input>
                    <input type="submit"></input>
                </form>
                <div className="childAccount1">
                </div>
            </div>
            {/* <style>
                {
                    `
                    #formpost {
                        display:none;
                    }
                    .parentTransaction{
                        color: black;
                        margin-left: 265px;
                        border-radius: 30px 0px 0px 30px;
                        // height: 100vh;
                    }
                    .childTransaction{
                        display:flex;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .childTransaction table,tr{
                        margin: 10px;
                        width: 100%;
                    }
                    label{
                        background: #ffffff;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 10px;
                        color: black;
                    }
                    td{
                        background: #ffffff;
                        padding: 20px;
                    }
                    table, th, td,tr{
                        border-spacing: 0;
                        border-collapse: collapse;
                        background: #ffffff;
                    }
                    select, input{
                        color: #6d7782;
                        padding: 5px;
                        border-radius: 10px;
                    }
                    .buttonset{
                        text-align: center;
                        padding: 10px;
                        border-radius: 10px;
                        background: #ffffff;
                    }
                    .buttonset button{
                        margin: 10px;
                        background-color: #edefef;
                        text-align: center;
                        color: #d85539;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button{
                        background-color: #edefef;
                        text-align: center;
                        color: #6a7580;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 10px;
                        border-radius: 10px;
                        border: 0px solid black;
                    }
                    button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .buttonset button:hover{
                        border: 0px solid black;
                        background-color: #d85539;
                        color: white;
                    }
                    .blockside{
                        text-align: left;
                        background: #ffffff;
                        margin: 10px;
                        border-radius: 10px;
                        display: block;
                        // border: 1px solid #6a7580;
                    }
                    input{
                        color-scheme: light;
                    }
                    select, input{
                        background-color: white;
                        padding: 10px;
                        border: 1px solid #6a7580;
                    }
                    .tran_table div, table, tr, th,td{
                        padding: 3px;
                        background-color: #ffffff;
                    }
                    .tran_table div{
                        border-radius: 10px;
                    }
                    .tran_table{
                        overflow-y: scroll;
                        height: 50vh;
                        padding: 10px;
                        background-color: #ffffff;
                        text-align: center;
                        border-radius: 10px;
                        margin: 15px;
                        margin-top: 20px;
                    }
                    .tran_table::-webkit-scrollbar{
                        display: none;
                    }
                    .tran_table table{
                        width: 100%;   
                    }
                    .tran_table td,th{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    .tran_table th{
                        background-color: #d85539;
                        color: white;
                    }
                    .tran_table select{
                        padding: 5px;
                    }

                    .date{
                        margin-left: 40%;
                    }
                    .rowscount{
                        text-align: right;
                    }


                    .parentPopup {
                        position: fixed;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background: rgba(0, 0, 0, 0.8);
                        color: white;
                        z-index: 1;
                    }
                    .childPopup{
                        color: white;
                        z-index: 0;
                        position: fixed; 
                        top: 50%; left:50%;
                        width: 70%;
                        text-align: center;
                        transform:translate(-50%,-50%);
                        border-radius: 10px;
                        display: flex;
                        text-align: left;
                        flex-shrink: 0;
                        // flex-wrap: wrap;
                        align-items: center;
                        margin: 10px;
                        padding: 10px;
                        background: #ffffff;
                        z-index: 2;
                    }
                    .childPopup table{
                        width: 100%;
                    }
                    .childPopup div, label{
                        background: #ffffff;
                    }
                    .close{
                        display: fixed;
                    }
                    .childPopup select{
                        color: black;
                        border-radius: 10px;
                        margin-left: 10px;
                        margin-top: 10px;
                    }
                    `
                }
            </style> */}
        </>
    )
}


export default Merchantreport